import { Box, Flex, TabList, TabPanels, Tabs } from '@chakra-ui/react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

import { TabHeader } from '@/shared/components/home/TabHeader';
import { TabProfile } from '@/shared/components/home/TabProfile';
import { TabServices } from '@/shared/components/home/TabServices';
import { useTabGroup } from '@/shared/hooks/useTabGroup';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

enum HOME_TABS {
  TAB_SERVICES = 'Services',
  TAB_PROFILE = 'My Profile',
}

const Home = () => {
  const tabData = useTabGroup();
  const { t } = useTranslation();
  const { user } = useAuth0();
  const navigate = useNavigate();

  useEffect(() => {
    if (user && !user.email_verified) {
      navigate('/email-not-verified');
    }
  }, [user]);

  return (
    <>
      <Helmet>
        <title>Home</title>
      </Helmet>
      <Box marginTop='8'>
        <Tabs {...tabData} colorScheme='black' w='full'>
          <Box maxW='900px' marginX='auto'>
            <Flex as={TabList} justifyContent='center'>
              <TabHeader>{t(HOME_TABS.TAB_SERVICES)}</TabHeader>
              <TabHeader>{t(HOME_TABS.TAB_PROFILE)}</TabHeader>
            </Flex>
          </Box>
          <Box position='relative' h='auto'>
            <TabPanels>
              <TabServices show={tabData.index === 0} />
              <TabProfile show={tabData.index === 1} />
            </TabPanels>
          </Box>
        </Tabs>
      </Box>
    </>
  );
};

export default Home;
