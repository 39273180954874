import HelpContent from '@/shared/components/help/HelpContent';
import MainLayout from '@/shared/components/layouts/MainLayout';

const Help = () => {
  return (
    <MainLayout hideProfile={true}>
      <HelpContent />
    </MainLayout>
  );
};

export default Help;
