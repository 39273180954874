import { defineStyleConfig } from '@chakra-ui/react';
import ThemeColors from '../colors/ThemeColors';

const Checkbox = defineStyleConfig({
  baseStyle: {
    control: {
      borderRadius: 4,
      borderWidth: 2,
      borderColor: ThemeColors.accentDarkBlue,
      _checked: {
        backgroundColor: ThemeColors.mightyBlue,
        border: 'none',
      },
    },
  },
});

export default Checkbox;
