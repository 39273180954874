import { Box, Center } from '@chakra-ui/react';

type CardAppProps = {
  title: string;
  link: string;
  imageSrc: string;
};

export const CardApp = ({ title, link, imageSrc }: CardAppProps) => {
  return (
    <Box
      as='a'
      minH={40}
      maxH={40}
      maxW={40}
      minW={40}
      bg='rgba(255, 255, 255, 0.9)'
      cursor='pointer'
      position='relative'
      borderRadius='sm'
      backgroundImage={`url(${imageSrc})`}
      backgroundSize='cover'
      backgroundPosition='center'
      backgroundRepeat='no-repeat'
      href={link}
      target='_blank'
      rel='noopener noreferrer'
      boxShadow='0px 0px 2px 1px rgba(0, 0, 0, 0.1)'
    >
      <Center
        as='a'
        bg='rgba(255, 255, 255, 0.9)'
        href={link}
        fontSize='sm'
        p={2}
        target='_blank'
        rel='noopener noreferrer'
        position={'absolute'}
        bottom='0'
        left='0'
        textAlign='center'
        mt='2'
        w='100%'
        color='black'
        fontWeight='bold'
      >
        {title}
      </Center>
    </Box>
  );
};
