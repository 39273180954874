import { Flex, Heading } from '@chakra-ui/react';
import LanguageSelector from './header/LanguageSelector';

import { Logo } from './header/Logo';
import { ProfileButton } from './header/ProfileButton';

const Header = ({ hideProfile }: { hideProfile: boolean }) => {
  return (
    <Flex
      as='header'
      gridArea='header'
      shadow='base'
      align='center'
      justify='space-between'
      zIndex='docked'
      px={6}
      backgroundColor='themeNavBackground'
      color='themeNavText'
      data-cy='header'
      minH={'20'}
    >
      <Logo />
      <Flex direction='row' alignItems='center' gap='8'>
        <Heading as='p' fontSize='sm' fontWeight={500} w='auto' verticalAlign={'center'} h='100%'>
          <a href='https://www.sandiego.gov/my-sd-login-support' target='_blank' rel='noreferrer'>
            Help
          </a>
        </Heading>
        <LanguageSelector />
        {!hideProfile && <ProfileButton />}
      </Flex>
    </Flex>
  );
};

export default Header;
