const domain = import.meta.env.VITE_AUTH0_DOMAIN;

type UserMetadata = {
  firstName: string;
  lastName: string;
  phoneNumber: string;
  streetAddress: string;
  zipcode: string;
};

export const getAccessToken = async (getAccessTokenSilently: () => Promise<string>) => {
  return getAccessTokenSilently();
};

export const getUserMetadata = async (user: string, getAccessTokenSilently: () => Promise<string>) => {
  const accessToken = await getAccessToken(getAccessTokenSilently);

  const userDetailsByIdUrl = `https://${domain}/api/v2/users/${user}`;

  const metadataResponse = await fetch(userDetailsByIdUrl, {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const { user_metadata } = await metadataResponse.json();
  return user_metadata;
};

export const updateUserProfile = async (
  data: UserMetadata,
  user: string,
  getAccessTokenSilently: () => Promise<string>
) => {
  const accessToken = await getAccessToken(getAccessTokenSilently);

  const updateUserUrl = `https://${domain}/api/v2/users/${user}`;

  const updateUserResponse = await fetch(updateUserUrl, {
    method: 'PATCH',
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      user_metadata: {
        first_name: data.firstName,
        last_name: data.lastName,
        phone_number: data.phoneNumber,
        street_address: data.streetAddress,
        zip_code: data.zipcode,
      },
    }),
  });

  if (!updateUserResponse.ok) {
    throw new Error(`Failed to update user: ${updateUserResponse.status}`);
  }
};

export const sendPasswordReset = async (email: string) => {
  const pwResetUrl = `https://${domain}/dbconnections/change_password`;
  const pwResetResponse = await fetch(pwResetUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify({
      clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
      email: email,
      connection: 'Username-Password-Authentication',
    }),
  });

  if (!pwResetResponse.ok) {
    throw new Error(`Failed to send reset password email: ${pwResetResponse.status}`);
  }
};