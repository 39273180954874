import { defineStyleConfig } from '@chakra-ui/react';
import Fonts from './Fonts';
import ThemeColors from '../colors/ThemeColors';

const Select = defineStyleConfig({
  variants: {
    default: {
      field: {
        border: '2px solid',
        borderColor: ThemeColors.themeInputBorder,
        bg: ThemeColors.themeInputBg,
        fontFamily: Fonts.openSans,
        fontSize: '16px',
        _placeholder: {
          color: ThemeColors.themeInputBg,
          fontSize: '16px',
          fontFamily: Fonts.openSans,
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'lg',
  },
});

export default Select;
