import { useAuth0 } from '@auth0/auth0-react';
import { Button, Center, Flex, Input, Spinner, Text, useToast } from '@chakra-ui/react';
import { FC, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useMutationApi } from '@/shared/api/useMutationApi';
import Header from '@/shared/components/layouts/Header';

const VerifyEmail: FC = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const toast = useToast();
  const [email, setEmail] = useState('');

  const handleEmailSent = () => {
    toast({
      title: `${t('Verification email sent to')} ${email}`,
      status: 'success',
    });
  };

  const handleEmailVerificationError = () => {
    toast({
      title: t('Error sending verification email'),
      status: 'error',
    });
  };

  const [{ loading }, resendEmailVerification] = useMutationApi(
    {
      method: 'POST',
      url: `/auth0/users/resend-verification-email`,
    },
    {
      onSuccess: handleEmailSent,
      onError: handleEmailVerificationError,
    }
  );

  const handleResendEmailVerification = async () => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email || !validRegex.test(email)) {
      toast({
        title: t('Please enter a valid email'),
        status: 'error',
      });
      return;
    }

    await resendEmailVerification({ data: { email } });
  };

  return (
    <>
      <Helmet>
        <title>Email Not Verified</title>
      </Helmet>
      <Header hideProfile={true} />
      <Center h='100vh'>
        <Flex direction='column' justifyContent='space-between' alignItems='center' mb='4' h={72}>
          {loading ? (
            <Spinner />
          ) : (
            <>
              <Text>{t('Verification email sent')}</Text>
              <Text>{t('If you did not...')}</Text>
              <Flex direction='column'>
                <Input placeholder={t('Email')} value={email} onChange={e => setEmail(e.target.value)} mb={0} />
                <Button mt={1} size={'sm'} onClick={handleResendEmailVerification}>
                  {t('Resend email verification')}
                </Button>
              </Flex>
              <Button mt={5} size={'sm'} onClick={() => logout()}>
                {t('Return to login')}
              </Button>
            </>
          )}
        </Flex>
      </Center>
    </>
  );
};

export default VerifyEmail;
