import { useEffect, useState } from 'react';

export const useTabGroup = (defaultIndex?: number) => {
  const [index, setTabIndex] = useState(defaultIndex || 0);
  const [previndex, setPrevIndex] = useState(index);

  useEffect(() => {
    setPrevIndex(index);
  }, [index]);

  const onChange = (index: number) => {
    setTabIndex(index);
  };

  return { index, previndex, onChange };
};
