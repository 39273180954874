import { GridItem, Heading } from '@chakra-ui/react';
import { ReactNode } from 'react';

type HelpTitleProps = {
  children: ReactNode;
};

const HelpTitle = ({ children }: HelpTitleProps) => {
  return (
    <GridItem
      w='100%'
      h='12'
      bg='accentSpruce'
      color='white'
      display='flex'
      alignItems='center'
      justifyContent='center'
    >
      <Heading as='h1' size='md' textAlign='center'>
        {children}
      </Heading>
    </GridItem>
  );
};

export default HelpTitle;
