/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Key } from 'swr';
import useSWRMutation, { SWRMutationConfiguration, SWRMutationResponse } from 'swr/mutation';
import { authenticatedAxiosInstance } from './instances';

type SWRResponseAdapted<Data, Error> = Omit<
  SWRMutationResponse<Data, Error, AxiosRequestConfig, Key>,
  'isLoading' | 'data' | 'trigger'
> & {
  loading: boolean;
  data?: Data;
  response?: AxiosResponse<Data>;
};

export type Trigger<Data> = (args?: AxiosRequestConfig) => Promise<AxiosResponse<Data>>;

type Return<Data, Error> = [SWRResponseAdapted<Data, Error>, Trigger<Data>];

type Config<Data> = SWRMutationConfiguration<Data, Error, AxiosRequestConfig, Key, any>;

type ExtraConfig<Data> = {
  blockFetching?: boolean;
} & Config<Data>;

type FetcherKey =
  | ({
      getToken: () => Promise<string>;
      companyId?: string;
    } & AxiosRequestConfig)
  | null;

const fetcher = async (
  key: FetcherKey,
  {
    arg,
  }: {
    arg: AxiosRequestConfig;
  }
) => {
  const token = await key?.getToken();
  return authenticatedAxiosInstance({
    headers: {
      Authorization: `Bearer ${token}`,
    },
    ...key,
    ...arg,
  }).then(res => res);
};

/**
 * This Hook is recommended to do mutation requests, i.e. POST, PUT, PATCH, DELETE.
 */
export const useMutationApi = <Data = unknown, Error = unknown>(
  fetchOptions: AxiosRequestConfig,
  config?: ExtraConfig<Data>
): Return<Data, Error> => {
  const key = config?.blockFetching
    ? null
    : {
        getToken: () => '',
        ...fetchOptions,
      };

  const swrResponse = useSWRMutation<AxiosResponse<Data>, Error, FetcherKey, AxiosRequestConfig>(
    key as FetcherKey,
    fetcher,
    config as SWRMutationConfiguration<AxiosResponse<Data>, Error, AxiosRequestConfig, Key, any>
  );

  const { isMutating, trigger, data: response } = swrResponse;
  return [
    {
      ...swrResponse,
      loading: isMutating,
      data: response?.data,
      response,
    },
    trigger as Trigger<Data>,
  ];
};
