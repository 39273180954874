import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet-async';
import { Box, ListItem, UnorderedList } from '@chakra-ui/react';
import HelpTitle from '@/shared/components/help/HelpTitle';
import HelpSection from '@/shared/components/help/HelpSection';

const HelpContent = () => {
  const { t } = useTranslation();

  return (
    <>
      <Helmet>
        <title>{t('Help')}</title>
        <link rel='canonical' href='https://sandiego.gov/' />
      </Helmet>
      <Box mt='8' px={['4', '6', '8']}>
        <UnorderedList p={0} listStyleType='none'>
          <ListItem mb='6'>
            <HelpTitle>{t('Account')}</HelpTitle>
            <UnorderedList ml='6' listStyleType='none'>
              <ListItem>
                <HelpSection title={t('How to register?')}>{t('register-help')}</HelpSection>
              </ListItem>
              <ListItem>
                <HelpSection title={t('How to sign in?')}>{t('sign-in-help')}</HelpSection>
              </ListItem>
              <ListItem>
                <HelpSection title={t('How to change info?')}>{t('change-info-help')}</HelpSection>
              </ListItem>
            </UnorderedList>
          </ListItem>

          <ListItem mb='6'>
            <HelpTitle>{t('Password')}</HelpTitle>
            <UnorderedList ml='6' listStyleType='none'>
              <ListItem>
                <HelpSection title={t('How to reset my password?')}>{t('reset-password-help')}</HelpSection>
              </ListItem>
              <ListItem>
                <HelpSection title={t('How to unlock my account?')}>{t('unlock-account-help')}</HelpSection>
              </ListItem>
            </UnorderedList>
          </ListItem>

          <ListItem mb='6'>
            <HelpTitle>{t('Security')}</HelpTitle>
            <UnorderedList ml='6' listStyleType='none'>
              <ListItem>
                <HelpSection title={t('What is MFA?')}>{t('mfa-help')}</HelpSection>
              </ListItem>
              <ListItem>
                <HelpSection title={t('How do I reset my MFA?')}>{t('reset-mfa-help')}</HelpSection>
              </ListItem>
            </UnorderedList>
          </ListItem>
        </UnorderedList>
      </Box>
    </>
  );
};

export default HelpContent;
