import { Box, Grid } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import Footer from './Footer';
import Header from './Header';

const MainLayout: FC<{ hideProfile: boolean; children: ReactNode }> = ({ hideProfile, children }) => {
  return (
    <>
      <Grid
        as='main'
        minHeight='100vh'
        gridTemplateAreas="'header' 'content' 'footer'"
        gridTemplateRows='
        var(--mid-sizes-header) 1fr var(--mid-sizes-footer)
      '
      >
        <Header hideProfile={hideProfile} />
        <Box as='section' gridArea='content' p={5} background='themeBackground'>
          {children}
        </Box>
        <Footer />
      </Grid>
    </>
  );
};

export default MainLayout;
