import { FC, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import UserProvider from '@/modules/users/components/UserProvider';
import LoadingLayout from '@/shared/components/layouts/LoadingLayout';
import MainLayout from '@/shared/components/layouts/MainLayout';

export const AuthenticationGuard: FC = () => {
  const AuthenticatedUserProvider = withAuthenticationRequired(UserProvider, {
    onRedirecting: () => <LoadingLayout />,
  });

  return (
    <Suspense fallback={<LoadingLayout />}>
      <AuthenticatedUserProvider>
        <MainLayout hideProfile={false}>
          <Outlet />
        </MainLayout>
      </AuthenticatedUserProvider>
    </Suspense>
  );
};
