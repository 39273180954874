import { FC } from 'react';
import { Spinner } from '../Spinner';
import MainLayout from './MainLayout';

const LoadingLayout: FC = () => {
  return (
    <MainLayout hideProfile={true}>
      <Spinner />
    </MainLayout>
  );
};

export default LoadingLayout;
