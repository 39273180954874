import { useTranslation } from 'react-i18next';
import { Flex, useConst, Text } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { getCurrentYear } from '@/shared/utils/date';
import ThemeColors from '@/shared/theme/colors/ThemeColors';

const FooterText: FC<{ href?: string; children: ReactNode }> = ({ href, children }) => (
  <a href={href} target='_blank' rel='noopener noreferrer'>
    <Text fontWeight={500} fontSize='xs' textDecoration={href ? 'underline' : 'none'}>
      {children}
    </Text>
  </a>
);

const Footer: FC = () => {
  const currentYear = useConst(getCurrentYear());
  const { t } = useTranslation();

  return (
    <Flex
      as='footer'
      align='center'
      justify='center'
      direction='column'
      data-cy='footer'
      bg={ThemeColors.themeFooterBg}
      py='4'
      position='fixed'
      left='0'
      bottom='0'
      width='100%'
    >
      <Flex color='white' justify='space-between' alignItems='center' direction='row' width='full' px='6'>
        <FooterText>© Copyright {currentYear} City Of San Diego.</FooterText>
        <Flex gap={8}>
          <FooterText href='https://www.sandiego.gov/disclaimers'>{t('Disclaimers')}</FooterText> |{' '}
          <FooterText href='https://www.sandiego.gov/privacy-policy'>{t('Privacy Policy')}</FooterText>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Footer;
