import { AppState, Auth0Provider } from '@auth0/auth0-react';
import { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthProvider: FC<PropsWithChildren> = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const handleRedirectCallback = (appState?: AppState) => {
    navigate(appState?.returnTo || window.location.pathname, { replace: true });
  };

  return (
    <Auth0Provider
      onRedirectCallback={handleRedirectCallback}
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        audience: `https://${import.meta.env.VITE_ORIGINAL_AUTH0_DOMAIN}/api/v2/`,
        redirect_uri: `${window.location.origin}/login/callback`,
        scope: 'openid email profile read:current_user update:current_user_metadata',
      }}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
