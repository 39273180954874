import { useAuth0 } from '@auth0/auth0-react';
import useSWR from 'swr';
import { AxiosError } from 'axios';
import { defaultSwrConfig, fetcher, FetcherKey, FetcherResponse, FetchOptions, UseApiConfig } from './api.shared';

/**
 * This Hook is recommended to do listing requests, i.e. GET.
 */
export const useApi = <
  TData = unknown,
  TError = AxiosError<{
    error: string;
    path: string;
    statusCode: number;
    timestamp: string;
  }>,
>(
  { params, url }: FetchOptions,
  config?: UseApiConfig
) => {
  const { getAccessTokenSilently } = useAuth0();

  const key: FetcherKey | null = config?.blockFetching
    ? null
    : {
        url,
        params,
        getToken: getAccessTokenSilently,
      };

  const swrReturn = useSWR<FetcherResponse<TData>, TError>(key, fetcher, {
    ...defaultSwrConfig,
    ...config,
  });

  return [
    {
      ...swrReturn,
      response: swrReturn.data,
      data: swrReturn.data?.data,
      loading: swrReturn.isLoading,
    },
    swrReturn.mutate,
  ] as const;
};
