import {
  SpinnerProps,
  Spinner as ChakraSpinner,
  Stack,
  StackProps,
} from '@chakra-ui/react';
interface Props extends SpinnerProps {
  containerProps?: StackProps;
}

export const Spinner = ({ containerProps, ...spinnerProps }: Props) => (
  <Stack w='full' h='full' justify='center' align='center' {...containerProps}>
    <ChakraSpinner
      speed='0.65s'
      emptyColor='gray.200'
      color='blue.500'
      size='md'
      {...spinnerProps}
    />
  </Stack>
);
