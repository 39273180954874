export const resources = {
  en: {
    translation: {
      // Nav Bar
      Help: 'Help',
      // Home Page
      'My Profile': 'My Profile',
      Services: 'Services',
      'Search Apps...': 'Search Apps...',
      // Profile
      'First Name': 'First Name',
      'Last Name': 'Last Name',
      Email: 'Email',
      'Phone Number': 'Phone Number',
      'Street Address': 'Street Address',
      'Zip Code': 'Zip Code',
      'Error fetching user data': 'Error fetching user data',
      'Reset Password': 'Reset Password',
      'Reset MFA': 'Reset MFA',
      'Save Profile': 'Save Profile',
      'Phone Number must be a number': 'Phone Number must be a number and at least 10 digits',
      'Zipcode must be a number': 'Zipcode must be a number and at least 5 digits',
      'Profile updated successfully.': 'Profile updated successfully.',
      'Error updating profile.': 'Error updating profile.',
      'Reset Password email sent.': 'Reset Password email sent.',
      'Error sending Password Reset Email.': 'Error sending Password Reset Email.',
      // Help
      Account: 'Account',
      'How to register?': 'How to register?',
      'register-help': 'Go to the website at https://my.sandiego.gov/ click sign up and fill in your account info. When you\'ve filled out all the fields, click on the “Continue” button. After you click on Continue, check the email account you used to sign up and look for the account activation email that was just sent. If you\'re having trouble finding the email, remember to check your spam folder. Open the email and click the link inside to finish your registration.',
      'How to sign in?': 'How to sign in?',
      'sign-in-help': 'Go to the website at https://my.sandiego.gov/ enter the email address that you registered and password, then press continue.',
      'How to change info?': 'How to change info?',
      'change-info-help': 'You can change your profile settings by My Profile after logging in. From here you can edit your profile information. When you are finished making changes click on the “SAVE” button and then log out of your account and then log back in to ensure that your account information is properly updated.',
      Password: 'Password',
      'How to reset my password?': 'How to reset my password?',
      'reset-password-help': 'You can reset your password at your “My Profile”.',
      'How to unlock my account?': 'How to unlock my account?',
      'unlock-account-help': 'You can unlock your account by clicking the “Unblock” link sent to your registered email address. After viewing the account unlock confirmation page, you can navigate back to https://my.sandiego.gov/ to sign into your unlocked account.',
      Security: 'Security',
      'What is MFA?': 'What is MFA?',
      'mfa-help': 'When you enable multi-factor authentication on your account you will be required to provide a code that will be sent to you through your method of choice when you sign in to your account. This adds an additional layer of security to your account so that even if your password gets compromised your account will still be protected.',
      'How do I reset my MFA?': 'How do I reset my MFA?',
      'reset-mfa-help': 'You can reset your MFA at your “My Profile”',
      // Misc
      'Terms of Use': 'Terms of Use',
      'Disclaimers': 'Disclaimers',
      'Privacy Policy': 'Privacy Policy',
      'Verification email sent': 'A verification email has been sent to you.',
      'If you did not...': 'If you did not receive that email, check your junk email folder or enter your email again below to have the verification resent.',
      'Return to login': 'Return to login',
      'Resend email verification': 'Resend email verification',
      'Verification email sent to': 'Verification email sent to',
      'Error sending verification email': 'Error sending verification email',
      'Please enter a valid email': 'Please enter a valid email',
    },
  },
  es: {
    translation: {
      // Nav Bar
      Help: 'Ayuda',
      // Home
      'My Profile': 'Mi perfil',
      Services: 'Servicios',
      'Search Apps...': 'Buscar aplicaciones...',
      //Profile
      'First Name': 'Nombre',
      'Last Name': 'Apellido',
      Email: 'Correo electrónico',
      'Phone Number': 'Número de teléfono',
      'Street Address': 'Dirección',
      'Zip Code': 'Código Postal',
      'Error fetching user data': 'Error al obtener los datos del usuario',
      'Reset Password': 'Restablecer contraseña',
      'Reset MFA': 'Restablecer MFA',
      'Save Profile': 'Guardar perfil',
      'Phone Number must be a number': 'El número de teléfono debe ser un número y tener al menos 10 dígitos',
      'Zipcode must be a number': 'El código postal debe ser un número y al menos 5 dígitos',
      'Profile updated successfully.': 'Perfil actualizado correctamente.',
      'Error updating profile.': 'Error al actualizar el perfil.',
      'Reset Password email sent.': 'Correo electrónico de restablecimiento de contraseña enviado.',
      'Error sending Password Reset Email.': 'Error al enviar el correo electrónico de restablecimiento de contraseña.',
      // Help
      Account: 'Cuenta',
      'How to register?': '¿Cómo registrarse?',
      'register-help': 'Vaya al sitio web en https://my.sandiego.gov/, haga clic en registrarse y complete la información de su cuenta. Cuando hayas completado todos los campos, haz clic en el botón “Continuar”. Después de hacer clic en Continuar, verifique la cuenta de correo electrónico que utilizó para registrarse y busque el correo electrónico de activación de cuenta que acaba de enviar. Si tiene problemas para encontrar el correo electrónico, recuerde revisar su carpeta de spam. Abra el correo electrónico y haga clic en el enlace que se encuentra dentro para finalizar su registro.',
      'How to sign in?': '¿Cómo iniciar sesión?',
      'sign-in-help': 'Vaya al sitio web en https://my.sandiego.gov/ ingrese la dirección de correo electrónico que registró y la contraseña, luego presione continuar.',
      'How to change info?': '¿Cómo cambiar la información?',
      'change-info-help': 'Puede cambiar la configuración de su perfil en Mi perfil después de iniciar sesión. Desde aquí puede editar la información de su perfil. Cuando haya terminado de realizar cambios, haga clic en el botón "GUARDAR" y luego cierre la sesión de su cuenta y luego vuelva a iniciarla para asegurarse de que la información de su cuenta esté actualizada correctamente.',
      Password: 'Contraseña',
      'How to reset my password?': '¿Cómo restablecer mi contraseña?',
      'reset-password-help': 'Puede restablecer su contraseña en su "Mi perfil".',
      'How to unlock my account?': '¿Cómo desbloquear mi cuenta?',
      'unlock-account-help': 'Puede desbloquear su cuenta haciendo clic en el enlace "Desbloquear" enviado a su dirección de correo electrónico registrada. Después de ver la página de confirmación de desbloqueo de la cuenta, puede regresar a https://my.sandiego.gov/ para iniciar sesión en su cuenta desbloqueada.',
      Security: 'Seguridad',
      'What is MFA?': '¿Qué es MFA?',
      'mfa-help': 'Cuando habilite la autenticación multifactor en su cuenta, se le pedirá que proporcione un código que se le enviará a través del método que elija cuando inicie sesión en su cuenta. Esto agrega una capa adicional de seguridad a su cuenta, de modo que incluso si su contraseña se ve comprometida, su cuenta seguirá protegida.',
      'How do I reset my MFA?': '¿Cómo restablezco mi MFA?',
      'reset-mfa-help': 'Puede restablecer su MFA en su "Mi perfil".',
      // Misc
      'Terms of Use': 'Términos de uso',
      'Disclaimers': 'Descargos de responsabilidad',
      'Privacy Policy': 'Política de privacidad',
      'Verification email sent': 'Se le ha enviado un correo electrónico de verificación.',
      'If you did not...': 'Si no recibió ese correo electrónico, revise su carpeta de correo no deseado o ingrese su correo electrónico nuevamente a continuación para que se reenvíe la verificación.',
      'Return to login': 'Volver a iniciar sesión',
      'Resend email verification': 'Reenviar verificación por correo electrónico',
      'Verification email sent to': 'Correo de verificación enviado a',
      'Error sending verification email': 'Error al enviar correo de verificación',
      'Please enter a valid email': 'Por favor ingrese un correo electrónico válido',
    },
  },
  vi: {
    translation: {
        // Nav Bar
      Help: 'Giúp đỡ',
      // Home
      'My Profile': 'Thông tin của tôi',
      Services: 'Dịch vụ',
      'Search Apps...': 'Tìm kiếm ứng dụng...',
      //Profile
      'First Name': 'Tên đầu tiên',
      'Last Name': 'Họ',
      Email: 'E-mail',
      'Phone Number': 'Số điện thoại',
      'Street Address': 'Địa chỉ đường phố',
      'Zip Code': 'Mã Bưu Chính',
      'Error fetching user data': 'Lỗi khi tìm nạp dữ liệu người dùng',
      'Reset Password': 'Đặt lại mật khẩu',
      'Reset MFA': 'Đặt lại MFA',
      'Save Profile': 'Lưu hồ sơ',
      'Phone Number must be a number': 'Số điện thoại phải là một số và có ít nhất 10 chữ số',
      'Zipcode must be a number': 'Mã Zip phải là một số và ít nhất 5 chữ số',
      'Profile updated successfully.': 'Hô sơ được cập nhật thành công.',
      'Error updating profile.': 'Lỗi cập nhật hồ sơ.',
      'Reset Password email sent.': 'Đã gửi email đặt lại mật khẩu.',
      'Error sending Password Reset Email.': 'Lỗi gửi Email đặt lại mật khẩu.',
      // Help
      Account: 'Tài khoản',
      'How to register?': 'Làm thế nào để đăng ký?',
      'register-help': 'Truy cập trang web https://my.sandiego.gov/ nhấp vào đăng ký và điền thông tin tài khoản của bạn. Khi bạn đã điền vào tất cả các trường, hãy nhấp vào nút “Tiếp tục”. Sau khi bạn nhấp vào Tiếp tục, hãy kiểm tra tài khoản email bạn đã sử dụng để đăng ký và tìm email kích hoạt tài khoản vừa được gửi. Nếu bạn gặp khó khăn khi tìm email, hãy nhớ kiểm tra thư mục thư rác. Mở email và nhấp vào liên kết bên trong để hoàn tất đăng ký của bạn.',
      'How to sign in?': 'Làm thế nào để đăng nhập?',
      'sign-in-help': 'Truy cập website https://my.sandiego.gov/ nhập địa chỉ email bạn đã đăng ký và mật khẩu rồi nhấn tiếp tục.',
      'How to change info?': 'Làm thế nào để thay đổi thông tin?',
      'change-info-help': 'Bạn có thể thay đổi cài đặt hồ sơ của mình bằng Hồ sơ của tôi sau khi đăng nhập. Từ đây bạn có thể chỉnh sửa thông tin hồ sơ của mình. Khi bạn thực hiện xong các thay đổi, hãy nhấp vào nút “LƯU” rồi đăng xuất khỏi tài khoản của bạn rồi đăng nhập lại để đảm bảo rằng thông tin tài khoản của bạn được cập nhật chính xác.',
      Password: 'Mật khẩu',
      'How to reset my password?': 'Làm cách nào để đặt lại mật khẩu của tôi?',
      'reset-password-help': 'Bạn có thể đặt lại mật khẩu của mình tại “Hồ sơ của tôi”.',
      'How to unlock my account?': 'Làm cách nào để mở khóa tài khoản của tôi?',
      'unlock-account-help': 'Bạn có thể mở khóa tài khoản của mình bằng cách nhấp vào liên kết “Bỏ chặn” được gửi đến địa chỉ email đã đăng ký của bạn. Sau khi xem trang xác nhận mở khóa tài khoản, bạn có thể điều hướng quay lại https://my.sandiego.gov/ để đăng nhập vào tài khoản đã mở khóa của mình.',
      Security: 'Bảo vệ',
      'What is MFA?': 'MFA là gì?',
      'mfa-help': 'Khi bật xác thực đa yếu tố trên tài khoản của mình, bạn sẽ được yêu cầu cung cấp mã sẽ được gửi cho bạn thông qua phương thức bạn chọn khi đăng nhập vào tài khoản của mình. Điều này bổ sung thêm một lớp bảo mật cho tài khoản của bạn để ngay cả khi mật khẩu của bạn bị xâm phạm, tài khoản của bạn vẫn được bảo vệ.',
      'How do I reset my MFA?': 'Làm cách nào để đặt lại MFA của tôi?',
      'reset-mfa-help': 'Bạn có thể đặt lại MFA của mình tại “Hồ sơ của tôi”.',
      // Misc
      'Terms of Use': 'Điều khoản sử dụng',
      'Disclaimers': 'Tuyên bố miễn trừ trách nhiệm',
      'Privacy Policy': 'Chính sách bảo mật',
      'Verification email sent': 'Một email xác minh đã được gửi cho bạn.',
      'If you did not...': 'Nếu bạn không nhận được email đó, hãy kiểm tra thư mục email rác hoặc nhập lại email của bạn bên dưới để gửi lại xác minh.',
      'Return to login': 'Trở về trang đăng nhập',
      'Resend email verification': 'Gửi lại email xác minh',
      'Verification email sent to': 'Email xác minh được gửi tới',
      'Error sending verification email': 'Lỗi gửi email xác minh',
      'Please enter a valid email': 'Vui lòng nhập email hợp lệ',
    },
  },
  tl: {
    translation: {
      // Nav Bar
      Help: 'Tulong',
      // Home
      'My Profile': 'Aking Profile',
      Services: 'Mga serbisyo',
      'Search Apps...': 'Maghanap ng mga Application...',
      //Profile
      'First Name': 'Pangalan',
      'Last Name': 'Huling pangalan',
      Email: 'Email',
      'Phone Number': 'Numero ng telepono',
      'Street Address': 'Address ng Kalye',
      'Zip Code': 'Zip Code',
      'Error fetching user data': 'Error sa pagkuha ng data ng user',
      'Reset Password': 'I-reset ang Password',
      'Reset MFA': 'I-reset ang MFA',
      'Save Profile': 'I-save ang Profile',
      'Phone Number must be a number': 'Ang Numero ng Telepono ay dapat na isang numero at hindi bababa sa 10 digit',
      'Zipcode must be a number': 'Ang Zip Code ay dapat na isang numero at hindi bababa sa 5 digit',
      'Profile updated successfully.': 'Matagumpay na na-update ang profile.',
      'Error updating profile.': 'Error sa pag-update ng profile.',
      'Reset Password email sent.': 'I-reset ang Password email na ipinadala.',
      'Error sending Password Reset Email.': 'Error sa pagpapadala ng Password Reset Email.',
      // Help
      Account: 'Account',
      'How to register?': 'Kung paano magrehistro?',
      'register-help': 'Pumunta sa website sa https://my.sandiego.gov/ i-click ang sign up at punan ang impormasyon ng iyong account. Kapag napunan mo na ang lahat ng mga patlang, mag-click sa pindutang "Magpatuloy". Pagkatapos mong mag-click sa Magpatuloy, suriin ang email account na ginamit mo sa pag-sign up at hanapin ang email sa pagpapagana ng account na kapapadala lamang. Kung nagkakaproblema ka sa paghahanap ng email, tandaan na suriin ang iyong folder ng spam. Buksan ang email at i-click ang link sa loob para tapusin ang iyong pagpaparehistro.',
      'How to sign in?': 'Paano mag-sign in?',
      'sign-in-help': 'Pumunta sa website sa https://my.sandiego.gov/ ilagay ang email address na iyong inirehistro at password, pagkatapos ay pindutin ang magpatuloy.',
      'How to change info?': 'Paano baguhin ang impormasyon?',
      'change-info-help': 'Maaari mong baguhin ang iyong mga setting ng profile sa pamamagitan ng Aking Profile pagkatapos mag-log in. Mula dito maaari mong i-edit ang iyong impormasyon sa profile. Kapag tapos ka nang gumawa ng mga pagbabago, i-click ang "SAVE" na buton at pagkatapos ay mag-log out sa iyong account at pagkatapos ay mag-log in muli upang matiyak na ang impormasyon ng iyong account ay maayos na na-update.',
      Password: 'Password',
      'How to reset my password?': 'Paano i-reset ang aking password?',
      'reset-password-help': 'Maaari mong i-reset ang iyong password sa iyong "Aking Profile".',
      'How to unlock my account?': 'Paano i-unlock ang aking account?',
      'unlock-account-help': 'Maaari mong i-unlock ang iyong account sa pamamagitan ng pag-click sa link na “I-unblock” na ipinadala sa iyong nakarehistrong email address. Pagkatapos tingnan ang pahina ng kumpirmasyon sa pag-unlock ng account, maaari kang mag-navigate pabalik sa https://my.sandiego.gov/ upang mag-sign in sa iyong naka-unlock na account.',
      Security: 'Seguridad',
      'What is MFA?': 'Ano ang MFA?',
      'mfa-help': 'Kapag pinagana mo ang multi-factor authentication sa iyong account, kakailanganin mong magbigay ng code na ipapadala sa iyo sa pamamagitan ng iyong paraan ng pagpili kapag nag-sign in ka sa iyong account. Nagdaragdag ito ng karagdagang layer ng seguridad sa iyong account upang kahit na makompromiso ang iyong password ay mapoprotektahan pa rin ang iyong account.',
      'How do I reset my MFA?': 'Paano ko ire-reset ang aking MFA?',
      'reset-mfa-help': 'Maaari mong i-reset ang iyong MFA sa iyong “Aking Profile”',
      // Misc
      'Terms of Use': 'Mga Tuntunin ng Paggamit',
      'Disclaimers': 'Mga Disclaimer',
      'Privacy Policy': 'Patakaran sa Privacy',
      'Verification email sent': 'Ipinadala sa iyo ang isang email sa pagpapatunay.',
      'If you did not...': 'Kung hindi mo natanggap ang email na iyon, tingnan ang iyong junk email folder o ilagay muli ang iyong email sa ibaba upang muling maipadala ang pag-verify.',
      'Return to login': 'Bumalik sa pag-login',
      'Resend email verification': 'Muling ipadala ang pag-verify sa email',
      'Verification email sent to': 'Ipinadala ang email ng pagpapatunay sa',
      'Error sending verification email': 'Error sa pagpapadala ng verification email',
      'Please enter a valid email': 'Mangyaring magpasok ng wastong email',
    },
  },
  so: {
    translation: {
        // Nav Bar
      Help: 'I caawi',
      // Home
      'My Profile': 'Profile-kayga',
      Services: 'Adeegyada',
      'Search Apps...': 'Raadi Codsiyada...',
      //Profile
      'First Name': 'Magaca hore',
      'Last Name': 'Magaca Dambe',
      Email: 'iimaylka',
      'Phone Number': 'Lambarka taleefanka',
      'Street Address': 'Cinwaanka Waddada',
      'Zip Code': 'Koodhka sibka',
      'Error fetching user data': 'Khalad keenida xogta isticmaalaha',
      'Reset Password': 'Dib u deji erayga sirta ah',
      'Reset MFA': 'Dib u dajin MFA',
      'Save Profile': 'Keydi Profile',
      'Phone Number must be a number': 'Lambarka taleefoonku waa inuu noqdaa lambar iyo ugu yaraan 10 nambar',
      'Zipcode must be a number': 'Koodhka sibku waa inuu noqdaa tiro iyo ugu yaraan 5 nambar',
      'Profile updated successfully.': 'Xogta si guul leh ayaa loo cusboonaysiiyay.',
      'Error updating profile.': 'Cilad dib u cusboonaysiinta astaanta.',
      'Reset Password email sent.': 'Dib u deji erayga sirta ah iimaylka la soo diray.',
      'Error sending Password Reset Email.': 'Cilad diritaanka erayga sirta ah emaylka.',
      // Help
      Account: 'Xisaab',
      'How to register?': 'Sidee loo diiwaan geliyaa?',
      'register-help': 'Aad website-ka ah https://my.sandiego.gov/ dhagsii saxiix oo buuxi macluumaadka akoonkaaga. Markaad buuxisay dhammaan goobaha, dhagsii badhanka "Continue". Kadib markaad gujiso Continue, hubi iimaylka aad isticmaalaysay si aad wax isugu qorto oo raadi iimaylka hawlgelinta akoontiga ee hadda la soo diray. Haddii ay dhibaato kaa haysato helitaanka iimaylka, xasuuso inaad hubiso galkaaga spamka. Fur iimaylka oo dhagsii isku xirka gudaha si aad u dhammaystirto diiwaangelintaada.',
      'How to sign in?': 'Sidee loo soo galaa?',
      'sign-in-help': 'Tag website-ka ah https://my.sandiego.gov/ geli ciwaanka iimaylka aad diwaangelisay iyo erayga sirta ah, ka dib tabo sii wad.',
      'How to change info?': 'Sidee loo beddelaa macluumaadka?',
      'change-info-help': 'Waxaad ku bedeli kartaa profile Settings by My Profile ka dib markaad gasho. Halkan waxaad ka beddeli kartaa macluumaadkaaga profile. Marka aad dhammayso sameynta isbeddelada dhagsii badhanka "KAYDKA" ka dibna ka bax akoonkaaga ka dibna dib u gal si aad u hubiso in macluumaadka akoonkaaga si sax ah loo cusboonaysiiyay.',
      Password: 'Furaha',
      'How to reset my password?': 'Sidee dib loogu dejiyaa erayga sirta ah?',
      'reset-password-help': 'Waxaad dib u dejin kartaa eraygaaga sirta ah "My Profile".',
      'How to unlock my account?': 'Sida loo furo akoonkayga?',
      'unlock-account-help': 'Waxaad furi kartaa akoonkaaga adiga oo gujinaya xiriirka "Unblock" ee loo soo diray ciwaanka iimaylka ee diiwaan gashan. Kadib markaad aragto bogga xaqiijinta furitaanka akoontiga, waxaad dib ugu noqon kartaa https://my.sandiego.gov/ si aad u gasho akoonkaaga furan.',
      Security: 'Amniga',
      'What is MFA?': 'Waa maxay MFA?',
      'mfa-help': 'Markaad awood u yeelatid xaqiijinta arrimo badan oo koontadaada ah waxaa lagaaga baahan doonaa inaad keento kood lagugu soo diri doono habka aad dooratay markaad gasho akoonkaaga. Tani waxay koontadaada ku daraysaa lakab dheeraad ah oo ammaan ah si xitaa haddii eraygaaga sirta ah la jabiyo akoonkaaga weli waa la ilaalin doonaa.',
      'How do I reset my MFA?': 'Sideen dib u dajin karaa MFA-gayga?',
      'reset-mfa-help': 'Waxaad dib u dajin kartaa MFA-gaaga "My Profile"',
      // Misc
      'Terms of Use': 'Shuruudaha isticmaalka',
      'Disclaimers': 'Afeef',
      'Privacy Policy': 'Qaanuunka Arrimaha Khaaska ah',
      'Verification email sent': 'Iimayl xaqiijin ah ayaa laguu soo diray',
      'If you did not...': 'Haddii aanad helin iimaylkaas, hubi galka iimaylkaga junk ama geli iimaylkaga mar labaad si aad u hesho caddaymo.',
      'Return to login': 'Ku soo noqo galitaanka',
      'Resend email verification': 'Dib u soo dir xaqiijinta iimaylka',
      'Verification email sent to': 'Xaqiijinta iimaylka ayaa loo soo diray',
      'Error sending verification email': 'Khalad diritaanka iimaylka xaqiijinta',
      'Please enter a valid email': 'Fadlan geli iimayl sax ah',
    },
  },
  ar: {
    translation: {
      // Nav Bar
      Help: 'يساعد',
      // Home
      'My Profile': 'ملفي',
      Services: 'خدمات',
      'Search Apps...': 'تطبيقات البحث...',
      //Profile
      'First Name': 'الاسم الأول',
      'Last Name': 'اسم العائلة',
      Email: 'بريد إلكتروني',
      'Phone Number': 'رقم التليفون',
      'Street Address': 'عنوان الشارع',
      'Zip Code': 'الرمز البريدي',
      'Error fetching user data': 'حدث خطأ أثناء جلب بيانات المستخدم',
      'Reset Password': 'إعادة تعيين كلمة المرور',
      'Reset MFA': 'إعادة تعيين وزارة الخارجية',
      'Save Profile': 'احفظ الملف',
      'Phone Number must be a number': 'يجب أن يكون رقم الهاتف رقمًا ولا يقل عن 10 أرقام',
      'Zipcode must be a number': 'يجب أن يتكون الرمز البريدي من رقم مكون من 5 أرقام على الأقل',
      'Profile updated successfully.': 'تم تحديث الملف الشخصي بنجاح.',
      'Error updating profile.': 'حدث خطأ أثناء تحديث الملف الشخصي.',
      'Reset Password email sent.': 'تم إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور.',
      'Error sending Password Reset Email.': 'خطأ في إرسال البريد الإلكتروني لإعادة تعيين كلمة المرور.',
      // Help
      Account: 'حساب',
      'How to register?': 'كيفية التسجيل؟',
      'register-help': 'انتقل إلى موقع الويب https://my.sandiego.gov/ وانقر فوق "تسجيل" واملأ معلومات حسابك. عند الانتهاء من ملء كافة الحقول، انقر فوق الزر "متابعة". بعد النقر فوق "متابعة"، تحقق من حساب البريد الإلكتروني الذي استخدمته للتسجيل وابحث عن البريد الإلكتروني لتنشيط الحساب الذي تم إرساله للتو. إذا كنت تواجه مشكلة في العثور على البريد الإلكتروني، فتذكر التحقق من مجلد الرسائل غير المرغوب فيها. افتح البريد الإلكتروني وانقر على الرابط الموجود بداخله لإنهاء تسجيلك.',
      'How to sign in?': 'كيفية تسجيل الدخول؟',
      'sign-in-help': 'انتقل إلى الموقع https://my.sandiego.gov/ وأدخل عنوان البريد الإلكتروني الذي قمت بتسجيله وكلمة المرور، ثم اضغط على "متابعة".',
      'How to change info?': 'كيفية تغيير المعلومات؟',
      'change-info-help': 'يمكنك تغيير إعدادات ملف التعريف الخاص بك عن طريق ملف التعريف الخاص بي بعد تسجيل الدخول. ومن هنا يمكنك تعديل معلومات ملفك الشخصي. عند الانتهاء من إجراء التغييرات، انقر فوق الزر "حفظ" ثم قم بتسجيل الخروج من حسابك ثم قم بتسجيل الدخول مرة أخرى للتأكد من تحديث معلومات حسابك بشكل صحيح.',
      Password: 'كلمة المرور',
      'How to reset my password?': 'كيفية إعادة تعيين كلمة المرور الخاصة بي؟',
      'reset-password-help': 'يمكنك إعادة تعيين كلمة المرور الخاصة بك في "ملفي الشخصي".',
      'How to unlock my account?': 'كيفية فتح حسابي؟',
      'unlock-account-help': 'يمكنك فتح حسابك عن طريق النقر على رابط "إلغاء الحظر" المرسل إلى عنوان بريدك الإلكتروني المسجل. بعد عرض صفحة تأكيد إلغاء قفل الحساب، يمكنك العودة إلى https://my.sandiego.gov/ لتسجيل الدخول إلى حسابك الذي تم إلغاء قفله.',
      Security: 'حماية',
      'What is MFA?': 'ما هي وزارة الخارجية؟',
      'mfa-help': 'عندما تقوم بتمكين المصادقة متعددة العوامل على حسابك، سيُطلب منك تقديم رمز سيتم إرساله إليك من خلال الطريقة التي تختارها عند تسجيل الدخول إلى حسابك. وهذا يضيف طبقة إضافية من الأمان إلى حسابك، بحيث أنه حتى لو تم اختراق كلمة المرور الخاصة بك، فسيظل حسابك محميًا.',
      'How do I reset my MFA?': 'كيف يمكنني إعادة تعيين أسلوب MFA الخاص بي؟',
      'reset-mfa-help': 'يمكنك إعادة تعيين MFA الخاص بك في "ملفي الشخصي" الخاص بك',
      // Misc
      'Terms of Use': 'شروط الاستخدام',
      'Disclaimers': 'إخلاء المسؤولية',
      'Privacy Policy': 'سياسة الخصوصية',
      'Verification email sent': 'تم إرسال بريد إلكتروني للتحقق إليك.',
      'If you did not...': 'إذا لم تتلق هذا البريد الإلكتروني، فتحقق من مجلد البريد الإلكتروني غير الهام أو أدخل بريدك الإلكتروني مرة أخرى أدناه لإعادة إرسال التحقق.',
      'Return to login': 'العودة لتسجيل الدخول',
      'Resend email verification': 'إعادة إرسال التحقق من البريد الإلكتروني',
      'Verification email sent to': 'تم إرسال رسالة التحقق بالبريد الإلكتروني إلى',
      'Error sending verification email': 'خطأ في إرسال البريد الإلكتروني للتحقق',
      'Please enter a valid email': 'يرجى إدخال البريد الإلكتروني الصحيح',
    },
  },
  ja: {
    translation: {
        // Nav Bar
      Help: 'ヘルプ',
      // Home
      'My Profile': '私のプロフィール',
      Services: 'サービス',
      'Search Apps...': 'アプリケーションの検索...',
      //Profile
      'First Name': 'ファーストネーム',
      'Last Name': '苗字',
      Email: 'Eメール',
      'Phone Number': '電話番号',
      'Street Address': '住所',
      'Zip Code': '郵便番号',
      'Error fetching user data': 'ユーザーデータの取得中にエラーが発生しました',
      'Reset Password': 'パスワードを再設定する',
      'Reset MFA': 'MFA をリセット',
      'Save Profile': 'プロファイルを保存',
      'Phone Number must be a number': '電話番号は少なくとも 10 桁の数字である必要があります',
      'Zipcode must be a number': '郵便番号は少なくとも 5 桁の数字である必要があります',
      'Profile updated successfully.': 'プロファイルが正常に更新されました.',
      'Error updating profile.': 'プロファイルの更新中にエラーが発生しました.',
      'Reset Password email sent.': 'パスワードリセットメールを送信しました.',
      'Error sending Password Reset Email.': 'パスワードリセットメールの送信エラー.',
      // Help
      Account: 'アカウント',
      'How to register?': '登録方法は？',
      'register-help': 'Web サイト https://my.sandiego.gov/ にアクセスし、「サインアップ」をクリックしてアカウント情報を入力します。すべてのフィールドに入力したら、「続行」ボタンをクリックします。 [続行] をクリックした後、サインアップに使用した電子メール アカウントを確認し、送信されたばかりのアカウント アクティベーション電子メールを探します。メールが見つからない場合は、迷惑メール フォルダーを忘れずに確認してください。メールを開いて中のリンクをクリックすると登録が完了します。',
      'How to sign in?': 'サインインするにはどうすればよいですか?',
      'sign-in-help': 'ウェブサイト https://my.sandiego.gov/ にアクセスし、登録したメール アドレスとパスワードを入力し、[続行] を押します。',
      'How to change info?': '情報を変更するにはどうすればよいですか?',
      'change-info-help': 'ログイン後の「マイプロフィール」からプロフィール設定を変更することができます。 ここからプロフィール情報を編集することができます。変更が完了したら、「保存」ボタンをクリックしてアカウントからログアウトし、再度ログインして、アカウント情報が正しく更新されていることを確認します。',
      Password: 'パスワード',
      'How to reset my password?': 'パスワードをリセットするにはどうすればよいですか?',
      'reset-password-help': 'パスワードは「マイプロフィール」でリセットできます。',
      'How to unlock my account?': 'アカウントのロックを解除するにはどうすればよいですか?',
      'unlock-account-help': '登録したメールアドレスに送信された「ブロックを解除」リンクをクリックすると、アカウントのロックが解除されます。アカウントのロック解除の確認ページを表示した後、https://my.sandiego.gov/ に戻って、ロック解除されたアカウントにサインインできます。',
      Security: '安全',
      'What is MFA?': 'MFAとは何ですか?',
      'mfa-help': 'アカウントで多要素認証を有効にすると、アカウントにサインインするときに選択した方法で送信されるコードを入力する必要があります。これにより、アカウントに追加のセキュリティ層が追加されるため、パスワードが漏洩した場合でもアカウントは引き続き保護されます。',
      'How do I reset my MFA?': 'MFA をリセットするにはどうすればよいですか?',
      'reset-mfa-help': 'MFA は「マイプロフィール」でリセットできます。',
      // Misc
      'Terms of Use': '利用規約',
      'Disclaimers': '免責事項',
      'Privacy Policy': 'プライバシーポリシー',
      'Verification email sent': ' 確認メールが送信されました。',
      'If you did not...': 'その電子メールを受け取っていない場合は、迷惑メール フォルダーを確認するか、以下に電子メールを再度入力して確認を再送信してください。',
      'Return to login': 'ログインに戻る',
      'Resend email verification': 'メール確認を再送信する',
      'Verification email sent to': '確認メールの送信先',
      'Error sending verification email': '確認メールの送信エラー',
      'Please enter a valid email': '正しいメールアドレスを入力してください',
    },
  },
  lo: {
    translation: {
      // Nav Bar
      Help: 'ຊ່ວຍເຫຼືອ',
      // Home
      'My Profile': 'ໂປຣໄຟລ໌ຂອງຂ້ອຍ',
      Services: 'ການບໍລິການ',
      'Search Apps...': 'ຄົ້ນຫາແອັບພລິເຄຊັນ...',
      //Profile
      'First Name': 'ຊື່​ແທ້',
      'Last Name': 'ນາມ​ສະ​ກຸນ',
      Email: 'ອີເມວ',
      'Phone Number': 'ເບີ​ໂທລະ​ສັບ',
      'Street Address': 'ທີ່​ຢູ່​ຖະ​ຫນົນ',
      'Zip Code': 'ລະ​ຫັດ​ໄປ​ສະ​ນີ',
      'Error fetching user data': 'ເກີດຄວາມຜິດພາດໃນການດຶງຂໍ້ມູນຜູ້ໃຊ້',
      'Reset Password': 'ຣີເຊັດລະຫັດຜ່ານ',
      'Reset MFA': 'ຣີເຊັດ MFA',
      'Save Profile': 'ບັນທຶກໂປຣໄຟລ໌',
      'Phone Number must be a number': 'ເບີໂທລະສັບຈະຕ້ອງເປັນຕົວເລກ ແລະຢ່າງໜ້ອຍ 10 ຕົວເລກ',
      'Zipcode must be a number': 'ລະຫັດໄປສະນີຕ້ອງເປັນຕົວເລກ ແລະຢ່າງໜ້ອຍ 5 ຕົວເລກ',
      'Profile updated successfully.': 'ອັບເດດໂປຣໄຟລ໌ສຳເລັດແລ້ວ.',
      'Error updating profile.': 'ເກີດຄວາມຜິດພາດໃນການອັບເດດໂປຣໄຟລ໌.',
      'Reset Password email sent.': 'ຣີເຊັດລະຫັດຜ່ານອີເມວທີ່ສົ່ງແລ້ວ.',
      'Error sending Password Reset Email.': 'ເກີດຄວາມຜິດພາດໃນການສົ່ງລະຫັດຜ່ານໃໝ່ອີເມວ.',
      // Help
      Account: 'ບັນຊີ',
      'How to register?': 'ວິທີການລົງທະບຽນ?',
      'register-help': 'ໄປທີ່ເວັບໄຊທ໌ https://my.sandiego.gov/ ຄລິກລົງທະບຽນ ແລະຕື່ມຂໍ້ມູນບັນຊີຂອງທ່ານ. ເມື່ອທ່ານຕື່ມຂໍ້ມູນໃສ່ຊ່ອງຂໍ້ມູນທັງຫມົດ, ໃຫ້ຄລິກໃສ່ "ສືບຕໍ່" ປຸ່ມ. ຫຼັງຈາກທີ່ທ່ານຄລິກໃສ່ສືບຕໍ່, ໃຫ້ກວດເບິ່ງບັນຊີອີເມລ໌ທີ່ທ່ານໃຊ້ເພື່ອສະຫມັກແລະຊອກຫາອີເມລ໌ການກະຕຸ້ນບັນຊີທີ່ຫາກໍ່ສົ່ງໄປ. ຖ້າເຈົ້າມີບັນຫາໃນການຊອກຫາອີເມວ, ຢ່າລືມກວດເບິ່ງໂຟນເດີສະແປມຂອງເຈົ້າ. ເປີດອີເມລ໌ແລະຄລິກໃສ່ການເຊື່ອມຕໍ່ພາຍໃນເພື່ອສໍາເລັດການລົງທະບຽນຂອງທ່ານ.',
      'How to sign in?': 'ເຂົ້າສູ່ລະບົບແນວໃດ?',
      'sign-in-help': 'ໄປທີ່ເວັບໄຊທ໌ https://my.sandiego.gov/ ໃສ່ທີ່ຢູ່ອີເມວທີ່ທ່ານລົງທະບຽນແລະລະຫັດຜ່ານ, ຫຼັງຈາກນັ້ນກົດສືບຕໍ່.',
      'How to change info?': 'ການປ່ຽນແປງຂໍ້ມູນແນວໃດ?',
      'change-info-help': 'ທ່ານສາມາດປ່ຽນແປງການຕັ້ງຄ່າຂໍ້ມູນຂອງທ່ານໂດຍ My Profile ຫຼັງຈາກເຂົ້າສູ່ລະບົບ. ຈາກທີ່ນີ້ທ່ານສາມາດແກ້ໄຂຂໍ້ມູນຂ່າວສານຂອງທ່ານ. ເມື່ອທ່ານເຮັດການປ່ຽນແປງສໍາເລັດແລ້ວ, ໃຫ້ຄລິກໃສ່ປຸ່ມ "ບັນທຶກ" ແລະຫຼັງຈາກນັ້ນອອກຈາກລະບົບບັນຊີຂອງທ່ານແລະຫຼັງຈາກນັ້ນເຂົ້າສູ່ລະບົບເພື່ອໃຫ້ແນ່ໃຈວ່າຂໍ້ມູນບັນຊີຂອງທ່ານໄດ້ຖືກປັບປຸງຢ່າງຖືກຕ້ອງ.',
      Password: 'ລະຫັດຜ່ານ',
      'How to reset my password?': 'ວິທີການຕັ້ງລະຫັດຜ່ານຂອງຂ້ອຍໃຫມ່?',
      'reset-password-help': 'ທ່ານສາມາດຣີເຊັດລະຫັດຜ່ານຂອງທ່ານໄດ້ທີ່ “ໂປຣໄຟລ໌ຂອງຂ້ອຍ”.',
      'How to unlock my account?': 'ວິທີການປົດລັອກບັນຊີຂອງຂ້ອຍ?',
      'unlock-account-help': 'ທ່ານສາມາດປົດລັອກບັນຊີຂອງທ່ານໂດຍການຄລິກໃສ່ "ປົດລັອກ" ການເຊື່ອມຕໍ່ທີ່ສົ່ງໄປຫາທີ່ຢູ່ອີເມລ໌ທີ່ລົງທະບຽນຂອງທ່ານ. ຫຼັງຈາກເບິ່ງໜ້າການຢືນຢັນການປົດລັອກບັນຊີແລ້ວ, ທ່ານສາມາດກັບຄືນໄປທີ່ https://my.sandiego.gov/ ເພື່ອເຂົ້າສູ່ລະບົບບັນຊີປົດລັອກຂອງທ່ານ..',
      Security: 'ຄວາມປອດໄພ',
      'What is MFA?': 'MFA ແມ່ນຫຍັງ?',
      'mfa-help': 'ເມື່ອທ່ານເປີດໃຊ້ການກວດສອບຄວາມຖືກຕ້ອງຫຼາຍປັດໃຈຢູ່ໃນບັນຊີຂອງທ່ານ, ທ່ານຈະຕ້ອງໃຫ້ລະຫັດທີ່ຈະຖືກສົ່ງໄປຫາທ່ານໂດຍຜ່ານວິທີການທີ່ທ່ານເລືອກໃນເວລາທີ່ທ່ານເຂົ້າສູ່ລະບົບບັນຊີຂອງທ່ານ. ນີ້ຈະເພີ່ມຄວາມປອດໄພເພີ່ມເຕີມຕໍ່ກັບບັນຊີຂອງທ່ານ ເພື່ອໃຫ້ເຖິງແມ່ນວ່າລະຫັດຜ່ານຂອງທ່ານຖືກບຸກລຸກ ບັນຊີຂອງທ່ານຈະຍັງຖືກປົກປ້ອງຢູ່.',
      'How do I reset my MFA?': 'ຂ້ອຍຈະຣີເຊັດ MFA ຂອງຂ້ອຍໄດ້ແນວໃດ?',
      'reset-mfa-help': 'ທ່ານສາມາດຣີເຊັດ MFA ຂອງທ່ານໄດ້ທີ່ "ໂປຣໄຟລ໌ຂອງຂ້ອຍ".',
      // Misc
      'Terms of Use': 'ເງື່ອນໄຂການນໍາໃຊ້',
      'Disclaimers': 'ການປະຕິເສດຄວາມຮັບຜິດຊອບ',
      'Privacy Policy': 'ນະໂຍບາຍຄວາມເປັນສ່ວນຕົວ',
      'Verification email sent': 'ອີເມວຢືນຢັນໄດ້ຖືກສົ່ງໄປຫາທ່ານແລ້ວ.',
      'If you did not...': 'ຖ້າທ່ານບໍ່ໄດ້ຮັບອີເມວນັ້ນ, ໃຫ້ກວດເບິ່ງໂຟນເດີອີເມວຂີ້ເຫຍື້ອຂອງທ່ານ ຫຼື ປ້ອນອີເມວຂອງທ່ານອີກເທື່ອໜຶ່ງຢູ່ຂ້າງລຸ່ມເພື່ອໃຫ້ການຢັ້ງຢືນກັບຄືນມາ.',
      'Return to login': 'ກັບຄືນສູ່ການເຂົ້າສູ່ລະບົບ',
      'Resend email verification': 'ສົ່ງອີເມວຢືນຢັນຄືນໃໝ່',
      'Verification email sent to': 'ອີເມວຢືນຢັນຖືກສົ່ງໄປຫາ',
      'Error sending verification email': 'ເກີດຄວາມຜິດພາດໃນການສົ່ງອີເມວຢືນຢັນ',
      'Please enter a valid email': 'ກະລຸນາໃສ່ອີເມວທີ່ຖືກຕ້ອງ',
    },
  },
  zh: {
    translation: {
        // Nav Bar
      Help: '幫助',
      // Home
      'My Profile': '我的履歷',
      Services: '服務',
      'Search Apps...': '搜尋應用程式...',
      //Profile
      'First Name': '名',
      'Last Name': '姓',
      Email: '電子郵件',
      'Phone Number': '電話號碼',
      'Street Address': '街道地址',
      'Zip Code': '郵遞區號',
      'Error fetching user data': '取得用戶資料時出錯',
      'Reset Password': '重新設密碼',
      'Reset MFA': '重置MFA',
      'Save Profile': '儲存個人資訊',
      'Phone Number must be a number': '電話號碼必須是數字且至少 10 位數字',
      'Zipcode must be a number': '郵遞區號必須是數字且至少 5 位數字',
      'Profile updated successfully.': '個人資料更新成功.',
      'Error updating profile.': '更新個人檔案時發生錯誤.',
      'Reset Password email sent.': '重設密碼電子郵件已發送.',
      'Error sending Password Reset Email.': '發送密碼重設電子郵件時出錯.',
      // Help
      Account: '帳戶',
      'How to register?': '如何註冊？',
      'register-help': '造訪網站 https://my.sandiego.gov/ 點擊「註冊」並填寫您的帳戶資訊。填寫完所有欄位後，按一下「繼續」按鈕。點擊「繼續」後，檢查您用於註冊的電子郵件帳戶並尋找剛剛發送的帳戶啟動電子郵件。如果您找不到電子郵件，請記得檢查您的垃圾郵件資料夾。打開電子郵件並點擊其中的連結以完成註冊。',
      'How to sign in?': '如何登入？',
      'sign-in-help': '造訪網站 https://my.sandiego.gov/ 輸入您註冊的電子郵件地址和密碼，然後按繼續。',
      'How to change info?': '如何更改訊息？',
      'change-info-help': '登入後，您可以透過「我的個人資料」更改您的個人資料設定。您可以在此處編輯您的個人資料資訊。完成變更後，按一下「儲存」按鈕，然後登出您的帳戶，然後重新登錄，以確保您的帳戶資訊已正確更新。',
      Password: '密碼',
      'How to reset my password?': '如何重置我的密碼？',
      'reset-password-help': '您可以在「我的個人資料」中重設密碼。',
      'How to unlock my account?': '如何解鎖我的帳號？',
      'unlock-account-help': '您可以點擊發送到您註冊的電子郵件地址的「解鎖」連結來解鎖您的帳戶。查看帳戶解鎖確認頁面後，您可以導覽回 https://my.sandiego.gov/ 以登入您的解鎖帳戶。',
      Security: '安全',
      'What is MFA?': '什麼是MFA？',
      'mfa-help': '當您在帳戶上啟用多重身份驗證時，您需要提供一個代碼，該代碼將在您登入帳戶時透過您選擇的方法傳送給您。這為您的帳戶增加了額外的安全層，因此即使您的密碼被洩露，您的帳戶仍將受到保護。',
      'How do I reset my MFA?': '如何重置我的 MFA？',
      'reset-mfa-help': '您可以在「我的個人資料」中重設您的 MFA.',
      // Misc
      'Terms of Use': '使用條款',
      'Disclaimers': '免責聲明',
      'Privacy Policy': '隱私權政策',
      'Verification email sent': '驗證電子郵件已傳送給您。',
      'If you did not...': '如果您沒有收到該電子郵件，請檢查您的垃圾電子郵件資料夾或在下面再次輸入您的電子郵件以重新發送驗證。',
      'Return to login': '返回登入',
      'Resend email verification': '重新發送電子郵件驗證',
      'Verification email sent to': '驗證電子郵件已發送至',
      'Error sending verification email': '發送驗證電子郵件時發生錯誤',
      'Please enter a valid email': '請輸入有效電子郵件',
    },
  },
  ko: {
    translation: {
      // Nav Bar
      Help: '돕다',
      // Home
      'My Profile': '내 프로필',
      Services: '서비스',
      'Search Apps...': '애플리케이션 검색...',
      //Profile
      'First Name': '이름',
      'Last Name': '성',
      Email: '이메일',
      'Phone Number': '전화 번호',
      'Street Address': '거리 주소',
      'Zip Code': '우편 번호',
      'Error fetching user data': '사용자 데이터를 가져오는 중에 오류가 발생했습니다.',
      'Reset Password': '암호를 재설정',
      'Reset MFA': 'MFA 재설정',
      'Save Profile': '프로필 저장',
      'Phone Number must be a number': '전화번호는 숫자여야 하며 10자리 이상이어야 합니다.',
      'Zipcode must be a number': '우편번호는 숫자여야 하며 5자리 이상이어야 합니다.',
      'Profile updated successfully.': '프로필이 업데이트되었습니다.',
      'Error updating profile.': '프로필을 업데이트하는 중에 오류가 발생했습니다.',
      'Reset Password email sent.': '비밀번호 재설정 이메일이 전송되었습니다.',
      'Error sending Password Reset Email.': '비밀번호 재설정 이메일을 보내는 중에 오류가 발생했습니다.',
      // Help
      Account: '계정',
      'How to register?': '등록하는 방법?',
      'register-help': 'https://my.sandiego.gov/ 웹사이트로 이동하여 가입을 클릭하고 계정 정보를 입력하세요. 모든 항목을 입력하신 후 “계속” 버튼을 클릭하세요. 계속을 클릭한 후 가입 시 사용한 이메일 계정을 확인하고 방금 전송된 계정 활성화 이메일을 찾으세요. 이메일을 찾는 데 문제가 있는 경우 스팸 폴더를 확인하는 것을 잊지 마세요. 이메일을 열고 안에 있는 링크를 클릭하면 등록이 완료됩니다.',
      'How to sign in?': '로그인하는 방법?',
      'sign-in-help': 'https://my.sandiego.gov/ 사이트에 접속하여 등록한 이메일 주소와 비밀번호를 입력하고 계속을 누르세요.',
      'How to change info?': '정보를 변경하는 방법은 무엇입니까?',
      'change-info-help': '로그인 후 내 프로필에서 프로필 설정을 변경할 수 있습니다. 여기에서 프로필 정보를 편집할 수 있습니다. 변경을 마치면 "저장" 버튼을 클릭한 다음 계정에서 로그아웃한 다음 다시 로그인하여 계정 정보가 제대로 업데이트되었는지 확인하세요.',
      Password: '비밀번호',
      'How to reset my password?': '비밀번호를 재설정하는 방법은 무엇입니까?',
      'reset-password-help': "비밀번호는 '내 프로필'에서 재설정하실 수 있습니다.",
      'How to unlock my account?': '내 계정을 잠금 해제하는 방법은 무엇입니까?',
      'unlock-account-help': '등록된 이메일 주소로 전송된 "차단 해제" 링크를 클릭하면 계정을 잠금 해제할 수 있습니다. 계정 잠금 해제 확인 페이지를 본 후 https://my.sandiego.gov/로 다시 이동하여 잠금 해제된 계정에 로그인할 수 있습니다.',
      Security: '보안',
      'What is MFA?': 'MFA란 무엇입니까?',
      'mfa-help': '계정에서 다단계 인증을 활성화하면 계정에 로그인할 때 선택한 방법을 통해 전송될 코드를 제공해야 합니다. 이렇게 하면 계정에 추가 보안 계층이 추가되어 비밀번호가 유출되더라도 계정은 계속 보호됩니다.',
      'How do I reset my MFA?': 'MFA를 어떻게 재설정하나요?',
      'reset-mfa-help': '"내 프로필"에서 MFA를 재설정할 수 있습니다.',
      // Misc
      'Terms of Use': '이용약관',
      'Disclaimers': '면책조항',
      'Privacy Policy': '개인 정보 정책',
      'Verification email sent': '귀하에게 확인 이메일이 전송되었습니다.',
      'If you did not...': '해당 이메일을 받지 못한 경우 정크 메일 폴더를 확인하거나 아래에 이메일을 다시 입력하여 확인 메일을 다시 보내주세요.',
      'Return to login': '로그인으로 돌아가기',
      'Resend email verification': '이메일 확인 다시 보내기',
      'Verification email sent to': '확인 이메일이 다음 주소로 전송되었습니다.',
      'Error sending verification email': '확인 이메일을 보내는 중에 오류가 발생했습니다.',
      'Please enter a valid email': '유효한 이메일을 입력해주세요',
    },
  },
  km: {
    translation: {
        // Nav Bar
      Help: 'ជំនួយ',
      // Home
      'My Profile': 'ប្រវត្តិរូបរបស់ខ្ញុំ',
      Services: 'សេវាកម្ម',
      'Search Apps...': 'ស្វែងរកកម្មវិធី...',
      //Profile
      'First Name': 'ឈ្មោះដំបូង',
      'Last Name': 'នាមត្រកូល',
      Email: 'អ៊ីមែល',
      'Phone Number': 'លេខទូរសព្ទ',
      'Street Address': 'អាស័យ​ដ្ឋាន​ផ្លូវ',
      'Zip Code': 'លេខ​កូដ​តំបន់',
      'Error fetching user data': 'កំហុសក្នុងការទាញយកទិន្នន័យអ្នកប្រើប្រាស់',
      'Reset Password': 'កំណត់ពាក្យសម្ងាត់ឡើងវិញ',
      'Reset MFA': 'កំណត់ MFA ឡើងវិញ',
      'Save Profile': 'រក្សាទុកប្រវត្តិរូប',
      'Phone Number must be a number': 'លេខទូរស័ព្ទត្រូវតែជាលេខ និងយ៉ាងហោចណាស់ 10 ខ្ទង់',
      'Zipcode must be a number': 'លេខកូដប្រៃសណីយ៍ត្រូវតែជាលេខ និងយ៉ាងហោចណាស់ 5 ខ្ទង់',
      'Profile updated successfully.': 'បានធ្វើបច្ចុប្បន្នភាពទម្រង់ដោយជោគជ័យ.',
      'Error updating profile.': 'កំហុសក្នុងការធ្វើបច្ចុប្បន្នភាពទម្រង់.',
      'Reset Password email sent.': 'កំណត់អ៊ីមែលពាក្យសម្ងាត់ឡើងវិញដែលបានផ្ញើ.',
      'Error sending Password Reset Email.': 'កំហុសក្នុងការផ្ញើអ៊ីមែលកំណត់ពាក្យសម្ងាត់ឡើងវិញ.',
      // Help
      Account: 'គណនី',
      'How to register?': 'តើត្រូវចុះឈ្មោះដោយរបៀបណា?',
      'register-help': 'ចូលទៅកាន់គេហទំព័រ https://my.sandiego.gov/ ចុចចុះឈ្មោះ ហើយបំពេញព័ត៌មានគណនីរបស់អ្នក។ នៅពេលអ្នកបំពេញវាលទាំងអស់សូមចុចលើប៊ូតុង "បន្ត" ។ បន្ទាប់ពីអ្នកចុចលើ Continue សូមពិនិត្យមើលគណនីអ៊ីមែលដែលអ្នកបានប្រើដើម្បីចុះឈ្មោះ ហើយរកមើលអ៊ីមែលសកម្មគណនីដែលទើបតែបានផ្ញើ។ ប្រសិនបើអ្នកមានបញ្ហាក្នុងការស្វែងរកអ៊ីមែល កុំភ្លេចពិនិត្យមើលថតសារឥតបានការរបស់អ្នក។ បើកអ៊ីមែល ហើយចុចលើតំណភ្ជាប់ខាងក្នុង ដើម្បីបញ្ចប់ការចុះឈ្មោះរបស់អ្នក។',
      'How to sign in?': 'តើត្រូវចូលដោយរបៀបណា?',
      'sign-in-help': 'ចូលទៅកាន់គេហទំព័រ https://my.sandiego.gov/ បញ្ចូលអាសយដ្ឋានអ៊ីមែលដែលអ្នកបានចុះឈ្មោះ និងពាក្យសម្ងាត់ បន្ទាប់មកចុចបន្ត។',
      'How to change info?': 'តើធ្វើដូចម្តេចដើម្បីផ្លាស់ប្តូរព័ត៌មាន?',
      'change-info-help': 'អ្នកអាចផ្លាស់ប្តូរការកំណត់ទម្រង់របស់អ្នកដោយ My Profile បន្ទាប់ពីចូល។ ពីទីនេះអ្នកអាចកែសម្រួលព័ត៌មានប្រវត្តិរូបរបស់អ្នក។ នៅពេលអ្នកបញ្ចប់ការកែប្រែ ចុចលើប៊ូតុង "រក្សាទុក" ហើយបន្ទាប់មកចេញពីគណនីរបស់អ្នក បន្ទាប់មកចូលវិញ ដើម្បីធានាថាព័ត៌មានគណនីរបស់អ្នកត្រូវបានធ្វើបច្ចុប្បន្នភាពត្រឹមត្រូវ។',
      Password: 'ពាក្យសម្ងាត់',
      'How to reset my password?': 'តើធ្វើដូចម្តេចដើម្បីកំណត់ពាក្យសម្ងាត់របស់ខ្ញុំឡើងវិញ?',
      'reset-password-help': 'អ្នកអាចកំណត់ពាក្យសម្ងាត់របស់អ្នកឡើងវិញនៅ "ប្រវត្តិរូបរបស់ខ្ញុំ" របស់អ្នក។',
      'How to unlock my account?': 'តើធ្វើដូចម្តេចដើម្បីដោះសោគណនីរបស់ខ្ញុំ?',
      'unlock-account-help': 'អ្នកអាចដោះសោគណនីរបស់អ្នកដោយចុចលើតំណ "Unblock" ដែលបានផ្ញើទៅកាន់អាសយដ្ឋានអ៊ីមែលដែលបានចុះឈ្មោះរបស់អ្នក។ បន្ទាប់ពីមើលទំព័របញ្ជាក់ការដោះសោគណនី អ្នកអាចរុករកត្រឡប់ទៅ https://my.sandiego.gov/ ដើម្បីចូលគណនីដែលបានដោះសោរបស់អ្នក។',
      Security: 'សន្តិសុខ',
      'What is MFA?': 'តើ MFA ជាអ្វី?',
      'mfa-help': 'នៅពេលអ្នកបើកដំណើរការការផ្ទៀងផ្ទាត់ពហុកត្តានៅលើគណនីរបស់អ្នក អ្នកនឹងត្រូវបានតម្រូវឱ្យផ្តល់លេខកូដដែលនឹងត្រូវបានផ្ញើទៅអ្នកតាមរយៈវិធីសាស្រ្តនៃជម្រើសរបស់អ្នក នៅពេលអ្នកចូលគណនីរបស់អ្នក។ វាបន្ថែមស្រទាប់សុវត្ថិភាពបន្ថែមទៅក្នុងគណនីរបស់អ្នក ដូច្នេះបើទោះបីជាពាក្យសម្ងាត់របស់អ្នកត្រូវបានសម្របសម្រួលគណនីរបស់អ្នកនឹងនៅតែត្រូវបានការពារ។',
      'How do I reset my MFA?': 'តើខ្ញុំត្រូវកំណត់ MFA របស់ខ្ញុំឡើងវិញដោយរបៀបណា?',
      'reset-mfa-help': 'អ្នកអាចកំណត់ MFA របស់អ្នកឡើងវិញនៅ “My Profile” របស់អ្នក',
      // Misc
      'Terms of Use': 'ល័ក្ខខ័ណ្ឌនៃការប្រើប្រាស់',
      'Disclaimers': 'ការបដិសេធ',
      'Privacy Policy': 'គោលការណ៍​ភាព​ឯកជន',
      'Verification email sent': 'ប្រសិនបើអ្នកមិនបានទទួលអ៊ីមែលនោះទេ សូមពិនិត្យមើលថតអ៊ីមែលឥតបានការរបស់អ្នក ឬបញ្ចូលអ៊ីមែលរបស់អ្នកម្តងទៀតនៅខាងក្រោមដើម្បីឱ្យការផ្ទៀងផ្ទាត់ឡើងវិញ។',
      'If you did not...': '해당 이메일을 받지 못한 경우 정크 메일 폴더를 확인하거나 아래에 이메일을 다시 입력하여 확인 메일을 다시 보내주세요.',
      'Return to login': 'ត្រលប់ទៅការចូល',
      'Resend email verification': 'ផ្ញើការផ្ទៀងផ្ទាត់អ៊ីមែលឡើងវិញ',
      'Verification email sent to': 'អ៊ីមែលផ្ទៀងផ្ទាត់បានផ្ញើទៅ',
      'Error sending verification email': 'កំហុសក្នុងការផ្ញើអ៊ីមែលផ្ទៀងផ្ទាត់',
      'Please enter a valid email': 'សូមបញ្ចូលអ៊ីមែលត្រឹមត្រូវ។',
    },
  },
};
