import { defineStyleConfig } from '@chakra-ui/react';
import ThemeColors from '../colors/ThemeColors';

const Radio = defineStyleConfig({
  variants: {
    squared: {
      control: {
        borderRadius: 4,
        width: 4,
        height: 4,
        _checked: {
          backgroundColor: ThemeColors.mightyBlue,
        },
      },
    },
    full: {
      label: {
        width: 'full',
      },
    },
  },
});

export default Radio;
