import { defineStyleConfig } from '@chakra-ui/react';
import ThemeColors from '../colors/ThemeColors';
import Fonts from './Fonts';

const FormLabel = defineStyleConfig({
  baseStyle: {
    fontSize: '1em',
    color: ThemeColors.accentSpruce,
    fontFamily: Fonts.openSans,
    lineHeight: '1.4',
    marginBottom: 0,
  },
});

export default FormLabel;
