import { useMutationApi } from "@/shared/api/useMutationApi";
import { UseToastOptions, useToast } from "@chakra-ui/toast";


const deleteMfaToastByStatus: Record<'success' | 'error', UseToastOptions> = {
    success: {
        title: 'MFA Devices removed successfully',
        status: 'success',
    },
    error: {
        title: 'Could not remove MFA Devices',
        status: 'error',
    },
};

export const useDeleteMfa = (userId?: string) => {
    const toast = useToast();
    const url = `/auth0/users/${userId}/mfa`;
    const [{ loading, isMutating }, deleteMfa] = useMutationApi(
        {
            method: 'DELETE',
            url,
        },
        {
            onError: error => {
                toast({
                    ...deleteMfaToastByStatus.error,
                    description: error.message,
                });
            },
            onSuccess: async () => {
                toast({
                    ...deleteMfaToastByStatus.success,
                });
            },
        }
    );

    const onDelete = async () => {
        await deleteMfa();
    };

    return {
        onDelete,
        loading: loading || isMutating,
    };
};