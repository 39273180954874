import { Box, Flex, Input, InputGroup, InputRightElement, Slide } from '@chakra-ui/react';

import { getServiceAppImageSrc } from '@/shared/constants/ServiceAppImages';
import { CardApp } from './CardApp';
import { useServicesApp } from './useServiceApp';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth0 } from '@auth0/auth0-react';

type TabServicesProps = {
  show: boolean;
};

export const TabServices = ({ show }: TabServicesProps) => {
  if (!show) return null;

  const { t } = useTranslation();
  const { user } = useAuth0();
  const { apps: servicesApps } = useServicesApp(user!.sub!);
  const [searchValue, setSearchValue] = useState<string>('');

  const filteredApps = servicesApps.filter(app => {
    return app.name.toLowerCase().includes(searchValue.toLowerCase());
  });

  const handleSearchChange: React.ChangeEventHandler<HTMLInputElement> = event => {
    setSearchValue(event.target.value);
  };

  return (
    <Slide direction={'left'} in style={{ position: 'absolute' }}>
      <Box px='10%' mt='8'>
        <Flex justifyContent='center' alignItems='center' mb='4'>
          <InputGroup w='300px'>
            <InputRightElement pointerEvents='none'>
              <FontAwesomeIcon icon={faSearch} color='--secondary-gray' />
            </InputRightElement>
            <Input value={searchValue} onChange={handleSearchChange} placeholder={t('Search Apps...')} />
          </InputGroup>
        </Flex>
        <Flex gap={10}>
          {filteredApps.map(app => (
            <CardApp key={app.id} title={app.name} link={app.link} imageSrc={getServiceAppImageSrc(app.image)} />
          ))}
        </Flex>
      </Box>
    </Slide>
  );
};
