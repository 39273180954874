import Auth0Error from '@/modules/error/pages/Auth0Error.page';
import { AppState, useAuth0 } from '@auth0/auth0-react';
import { Center, Spinner } from '@chakra-ui/react';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const CallbackPage: FC = () => {
  const { handleRedirectCallback, error } = useAuth0();
  const navigate = useNavigate();

  // This effect will normally NEVER run. It should only run in the
  // never-supposed-to-happen case someone goes specifically to /login/callback
  useEffect(() => {
    if (error) {
      return;
    }
    const handleRedirect = async () => {
      try {
        const { appState } = await handleRedirectCallback(window.location.href);
        navigate((appState as AppState)?.returnTo ?? '/', { replace: true });
      } catch (err) {
        navigate('/', { replace: true });
      }
    };

    // Only redirect after 2 seconds
    const timeoutId = setTimeout(handleRedirect, 2000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [error, handleRedirectCallback, navigate]);

  if (error && error.message?.includes('Please verify your email')) navigate('/email-not-verified');

  if (error) return <Auth0Error />;

  return (
    <Center h='100vh'>
      <Spinner />
    </Center>
  );
};

export default CallbackPage;
