const brandColors = {
  mightyGold: 'var(--mighty-gold)',
  mightyRed: 'var(--mighty-red)',
  mightyBlue: 'var(--mighty-blue)',
  mightyDarkGrayBlue: 'var(--mighty-dark-gray-blue)',
  defaultTenantColor: '#d91920' /* Mighty Red */,
};

const accentColors = {
  accentOrange: 'var(--primary-orange)',
  accentBarBlue: 'var(--accent-bar-blue)',
  accentBlue: 'var(--primary-blue)',
  accentGunmetal: 'var(--accent-gunmetal)',
  accentSpruce: 'var(--primary-dark)',
  accentUserGold: 'var(--accent-user-gold)',
  accentUserGoldTransparent20: 'var(--accent-user-gold-transparent-20)',
  accentGroupPurple: 'var(--accent-group-purple)',
  accentGroupPurpleTransparent20: 'var(--accent-group-purple-transparent-20)',
  accentAppGreen: 'var(--accent-app-green)',
  accentAppGreenTransparent20: 'var(--accent-app-green-transparent-20)',
  accentADAGrayText: 'var(--accent-ADA-gray-text)',
  accentSuperLightBlue: 'var(--accent-super-light-blue)',
  accentDarkBlue: 'var(--accent-dark-blue)',
  accentAntiFlashWhite: 'var(--accent-anti-flash-white)',
  accentLightBlue: 'var(--accent-light-blue)',
  accentBlack: 'var(--accent-black)',
  accentLightSilver: 'var(--accent-light-silver)',
  accentSeaFoam: 'var(--accent-sea-foam)',
  accentSeaFoamTransparent: 'var(--accent-sea-foam-transparent)',
  accentWhite: 'var(--accent-white)',
  accentGrayBlueTransparent25: 'var(--accent-gray-blue-transparent-25)',
  accentAlmostBlack: 'var(--accent-almost-black)',
  accentHonoluluBlue: 'var(--accent-Honolulu-blue)',
  accentBlackTransparent15: 'var(--accent-black-transparent-15)',
  accentLightRed: 'var(--accent-light-red)',
  accentDarkRed: 'var(--accent-dark-red)',
  accentBlackCoral: 'var(--accent-black-coral)',
  accentDarkSilver: 'var(--accent-dark-silver)',
  accentCinnabar: 'var(--accent-cinnabar)',
  accentMediumSeaGreen: 'var(--accent-medium-sea-green)',
  accentLightGray: 'var(--accent-light-gray)',
};

const themeColors = {
  themeBackground: 'var(--theme-background)',
  themeNavBackground: 'var(--theme-nav-background)',
  themeFooterBg: 'var(--theme-footer-bg)',
  themeNavText: 'var(--theme-nav-text)',
  themeNavCtxMenuBg: 'var(--theme-nav-ctx-menu-bg)',
  themeTenantOVBackground: 'var(--theme-tenant-ov-background)',
  themeTenantOVBorder: 'var(--theme-tenant-ov-border)',
  themeIconFill: 'var(--theme-icon-fill)',
  themeGraphTransparent: 'var(--theme-graph-transparent)',
  themeSummaryGraphBackground: 'var(--theme-summary-graph-background)',
  themeSummaryGraphText: 'var(--theme-summary-graph-text)',
  themeTenantDashboardHeaderBg: 'var(--theme-tenant-dashboard-header-bg)',
  themeTenantDashboardBackground: 'var(--theme-tenant-dashboard-background)',
  themeTenantDashboardBorder: 'var(--theme-tenant-dashboard-border)',
  themeTenantDashboardBottomBorder: 'var(--theme-tenant-dashboard-bottom-border)',
  themeProductionTenantBackground: 'var(--theme-production-tenant-background)',
  themeInfoCardDivider: 'var(--theme-info-card-divider)',
  themeInfoCardProdBg: 'var(--theme-info-card-prod-bg)',
  themeInfoCardNoProdBg: 'var(--theme-info-card-no-prod-bg)',
  themeInputBorder: 'var(--theme-input-border)',
  themeInputBg: 'var(--theme-input-bg)',
  themeInputText: 'var(--theme-input-text)',
  themeBoxBorder: 'var(--theme-input-border)',
  themeBoxBg: 'var(--theme-box-bg)',
  themeBackupJobBg: 'var(--theme-backup-job-bg)',
  themePortJobBg: 'var(--theme-port-job-bg)',
  themeMigrationJobBg: 'var(--theme-migration-job-bg)',
  themeIncrementalBackupBg: 'var(--theme-incremental-backup-bg)',
  themeFullBackupBg: 'var(--theme-full-backup-bg)',
  themeOptionsButton: 'var(--theme-options-button)',
  themeDefaultButton: 'var(--theme-default-button)',
  themeAddonBg: 'var(--theme-addon-bg)',
  themeDropdownOptionBg: 'var(--theme-dropdown-option-bg)',
  themeTooltipBg: 'var(--theme-tooltip-bg)',
  themeTextColor: 'var(--theme-text-color)',
  themeJobIncludeBg: 'var(--theme-job-include-bg)',
  themeUserCardBg: 'var(--theme-user-card-bg)',
  themeButtonFilterBg: 'var(--th/eme-button-filter-bg)',
  themeLogItemBorderInfo: 'var(--theme-log-item-border-info)',
  themeLogItemBgInfo: 'var(--theme-log-item-bg-info)',
  themeLogItemBorderError: 'var(--theme-log-item-border-error)',
  themeLogItemBgError: 'var(--theme-log-item-bg-error)',
  themeLogItemBorderWarn: 'var(--theme-log-item-border-warn)',
  themeLogItemBgWarn: 'var(--theme-log-item-bg-warn)',
  themeCompanyLogoBg: 'var(--theme-company-logo-bg)',
  themeLink: 'var(--theme-link)',
  themeMultipleBackupCountBg: 'var(--theme-multiple-backup-count-bg)',
  themeSingleBackupCountBg: 'var(--theme-single-backup-count-bg)',
};

export default {
  // brand
  ...brandColors,
  // Themes
  ...themeColors,
  // Accent
  ...accentColors,
  // Swatches
  primary: {
    50: '#ffe6e1',
    100: '#ffbcb1',
    200: '#ff9080',
    300: '#FD6048',
    400: '#fc3b1d',
    500: 'var(--mighty-red)',
    600: '#b11902',
    700: '#7f1100',
    800: '#4e0800',
    900: '#1f0000',
  },
  secondary: {
    50: '#dbf9ff',
    100: '#afe9ff',
    200: '#80d8ff',
    300: '#51c8fe',
    400: '#2cb9fc',
    500: 'var(--mighty-gray-blue)',
    600: '#0d7cb2',
    700: '#005D86',
    800: '#00354f',
    900: '#00131f',
  },
  accent: {
    50: '#fff3db',
    100: '#ffe2ad',
    200: '#ffd37d',
    300: '#fec84b',
    400: '#FEC325',
    500: 'var(--mighty-gold)',
    600: '#b26a00',
    700: '#7f4300',
    800: '#4d2300',
    900: '#1c0900',
  },
  blue: {
    50: '#EBF8FF',
    100: '#BEE3F8',
    200: 'var(--accent-bar-blue)',
    300: '#63B3ED',
    400: '#4299E1',
    500: 'var(--accent-loading-blue)',
    600: '#2B6CB0',
    700: '#2C5282',
    800: '#2A4365',
    900: '#1A365D',
  },
  gray: {
    50: '#f7fafc',
    100: '#edf2f7',
    200: '#e2e8f0',
    300: '#cbd5e0',
    400: '#a0aec0',
    500: '#718096',
    600: '#4a5568',
    700: '#2d3748',
    800: '#1a202c',
    900: '#171923',
  },
};
