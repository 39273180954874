import AuthProvider from '@/modules/auth/components/AuthProvider';
import ScrollToTop from '@/shared/utils/scrollToTop';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';

// We need this because AuthProvider and MaintenanceInterceptor must be
// inside RouterProvider in order
// to access useNavigate, but RouterProvider does not accept children
const RootLayout: FC = () => {
  return (
    <>
      <ScrollToTop />
      <AuthProvider>
        <Outlet />
      </AuthProvider>
    </>
  );
};

export default RootLayout;
