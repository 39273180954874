// cspell:ignore fontsource
import React from 'react';
import { createRoot } from 'react-dom/client';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { theme } from './shared/theme';
import { RouterProvider } from 'react-router-dom';
import { router } from './router';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';

// Fonts
import '@fontsource/merriweather';
import '@fontsource/open-sans'; // Defaults to weight 400
import '@fontsource/open-sans/400.css'; // Specify weight

import { toastOptions } from './shared/theme/toastOptions';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

const savedLanguage = localStorage.getItem('language');
if (savedLanguage) {
  i18n.changeLanguage(savedLanguage);
}

root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18n}>
      <ChakraProvider theme={theme} toastOptions={toastOptions}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <HelmetProvider>
          <Helmet titleTemplate='City of San Diego - %s' defaultTitle='City of San Diego' />
          <RouterProvider router={router} />
        </HelmetProvider>
      </ChakraProvider>
    </I18nextProvider>
  </React.StrictMode>
);
