import { extendTheme, ThemeConfig, CustomThemeTypings } from '@chakra-ui/react';

import './colors/index.css';

import Button from './components/Button';
import Checkbox from './components/Checkbox';
import Fonts from './components/Fonts';
import { FormControlStyles as FormControl } from './components/Form';
import FormLabel from './components/FormLabel';
import Input from './components/Input';
import NumberInput from './components/NumberInput';
import Link from './components/Link';
import Radio from './components/Radio';
import Select from './components/Select';
import Badge from './components/Badge';
import ThemeColors from './colors/ThemeColors';
import Menu from './components/Menu';

const styles = {
  global: {
    body: {
      bg: ThemeColors.themeBackground,
    },
    '::selection': {
      bg: ThemeColors.mightyGold,
      color: 'black',
    },
  },
};

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  cssVarPrefix: 'mid',
};

const sizes = {
  header: '50px',
  footer: '35px',
};

const fonts = {
  heading: Fonts.openSans,
  body: Fonts.openSans,
  paragraph: Fonts.openSans,
};

const components = {
  Button,
  Link,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  Select,
  Radio,
  Checkbox,
  Badge,
  Menu,
};

const customTheme: CustomThemeTypings = {
  fonts,
  colors: ThemeColors,
  config,
  styles,
  sizes,
  components,
};

export const theme = extendTheme(customTheme);
