import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';
import Fonts from './Fonts';
import ThemeColors from '../colors/ThemeColors';

const { defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  menuAnatomy.keys
);

const Menu = defineMultiStyleConfig({
  variants: {
    'outlined-field': {
      button: {
        background: ThemeColors.themeInputBg,
        border: '2px solid',
        borderColor: ThemeColors.themeInputBorder,
        borderRadius: 'md',
        fontFamily: Fonts.openSans,
        fontSize: '16px',
        _placeholder: {
          color: ThemeColors.accentGunmetal,
          fontSize: '16px',
          fontFamily: Fonts.openSans,
          background: ThemeColors.themeInputBg,
        },
        padding: '0 0.5rem 0 1rem',
        height: '48px',
        w: 'full',
        textAlign: 'left',
      },
      list: {
        width: '100%',
      },
    },
  },
});

export default Menu;
