import { defineStyleConfig } from '@chakra-ui/react';
import ThemeColors from '../colors/ThemeColors';

const Input = defineStyleConfig({
  variants: {
    default: {
      field: {
        border: '1px solid',
        borderRadius: '4px',
        borderColor: ThemeColors.themeInputBorder,
        fontSize: '.8125rem',
        padding: '.75em',
        margin: '0 0 1em 0',
        color: ThemeColors.themeInputText,
        _placeholder: {
          opacity: 0.8,
          fontWeight: 600,
        },
        _focus: {
          borderColor: ThemeColors.accentBlack,
          borderRadius: '4px',
          border: '2px solid',
        },
        _disabled: {
          bg: ThemeColors.themeInputBg,
          opacity: 0.5,
          cursor: 'not-allowed',
        },
      },
      addon: {
        bgColor: ThemeColors.themeAddonBg,
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'md',
  },
});

export default Input;
