// cspell:ignore datetime
import dayjs from 'dayjs';
import tz from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(tz);

const dateInputFormat = 'YYYY-MM-DD';
const dateFormat = 'M/D/YYYY';
const datetimeFormat = `h:mmA ${dateFormat}`;

const datetimeDetailed = 'MM/DD/YYYY, hh:mm:ss A';

const applyFormat =
  (format: string) =>
  (date: string, useLocalTime = true) => {
    if (!date) return '';
    let dayjsDate = dayjs.utc(date);
    if (useLocalTime) dayjsDate = dayjsDate.local();
    return dayjsDate.format(format);
  };

export const formatTimeDate = (
  date: Date | string,
  format = 'h:mmA MM/DD/YYYY'
): string => dayjs(date).format(format);

export const formatDateInput = applyFormat(dateInputFormat);

export const formatDate = applyFormat(dateFormat);

export const formatDatetime = applyFormat(datetimeFormat);

export const formatDetailedDatetime = applyFormat(datetimeDetailed);

export const fromDateToUTCDate = (date: string | number | Date): Date => {
  const myDate = dayjs.utc(date).toDate();
  const dateOnMilliseconds = myDate.getTime();
  const utcOffsetOnMilliseconds = myDate.getTimezoneOffset() * 60000;
  return new Date(dateOnMilliseconds + utcOffsetOnMilliseconds);
};

export const getUserTimezone = () => dayjs.tz.guess();

export const isPastDateFromToday = (date: Date) => {
  const today = new Date();
  return dayjs(date).isBefore(today);
};

export const getCurrentYear = () => dayjs().year();

export const isAfter = (thisDate: string, otherDate: string) => {
  return dayjs(thisDate).isAfter(dayjs(otherDate));
};
