import { GridItem, Heading, Text, Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

type HelpSectionProps = {
  children: ReactNode;
  title: string;
  borders?: boolean;
};

const HelpSection = ({ children, title, borders }: HelpSectionProps) => {
  return (
    <GridItem w='100%' borderLeft={borders ? '1px' : 'none'} borderRight={borders ? '1px' : 'none'} p='4'>
      <Box>
        <Heading as='h4' size='sm' mb='2'>
          {title}
        </Heading>
        <Text fontSize='md'>{children}</Text>
      </Box>
    </GridItem>
  );
};

export default HelpSection;
