import { useAuth0, User } from '@auth0/auth0-react';
import { createContext, FC, memo, ReactNode, useContext, useMemo } from 'react';

import LoadingLayout from '@/shared/components/layouts/LoadingLayout';

interface ContextProps {
  user: User;
}

const Context = createContext<ContextProps>({
  user: {} as User,
});

const ProviderContext: FC<{ children: ReactNode }> = ({ children }) => {
  const { user, isLoading } = useAuth0();

  if (isLoading || !user) return <LoadingLayout />;

  const providerValues = useMemo(
    () => ({
      user,
      isLoading,
    }),
    [isLoading]
  );
  return <Context.Provider value={providerValues}>{children}</Context.Provider>;
};

export const UserProvider = memo(ProviderContext);

export const useUserContext = () => {
  return useContext(Context);
};

export default UserProvider;
