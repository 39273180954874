import { useApi } from '@/shared/api/useApi';

interface ServiceApp {
  id: number;
  name: string;
  image: string;
  link: string;
}

export const useServicesApp = (userId: string) => {
  const [{ data }] = useApi<ServiceApp[]>({
    url: `/auth0/users/${userId}/apps`,
  });
  const apps = data ?? [];
  return { apps };
};
