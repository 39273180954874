import { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Navigate, Route } from 'react-router-dom';
import { AuthenticationGuard } from '@/modules/auth/components/AuthGuard';
import Home from '@/modules/home/pages/Home.page';

import CallbackPage from './modules/auth/components/CallbackPage';
import RootLayout from './shared/components/layouts/RootLayout';
import Help from './modules/home/pages/Help.page';
import VerifyEmail from './modules/error/pages/VerifyEmail.page';

const NotFound = lazy(() => import('@/modules/error/pages/NotFound.page'));
const Unauthorized = lazy(() => import('@/modules/unauthorized/pages/Unauthorized.page'));

export const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<RootLayout />}>
      {/* PRIVATE ROUTES */}
      <Route element={<AuthenticationGuard />}>
        <Route path='/' element={<Navigate to='/home' replace />} />
        <Route path='/home' element={<Home />} />
        {/* SETTINGS ROUTES */}
      </Route>
      {/* PUBLIC ROUTES */}
      <Route path='/login/callback' element={<CallbackPage />} />
      <Route path='/email-not-verified' element={<VerifyEmail />} />
      <Route path='/unauthorized' element={<Unauthorized />} />
      <Route path='/help' element={<Help />} />
      {/* 404 PAGE */}
      <Route path='*' element={<NotFound />} />
    </Route>
  )
);
