import { defineStyleConfig } from '@chakra-ui/react';
import ThemeColors from '../colors/ThemeColors';

const NumberInput = defineStyleConfig({
  variants: {
    default: {
      field: {
        border: '2px solid',
        borderColor: ThemeColors.themeInputBorder,
        fontSize: '16px',
        _placeholder: {
          color: ThemeColors.accentGunmetal,
          fontSize: '16px',
        },
      },
      addon: {
        bgColor: ThemeColors.themeInputBg,
      },
    },
  },
  defaultProps: {
    variant: 'default',
    size: 'lg',
  },
});

export default NumberInput;
