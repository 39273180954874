import { defineStyleConfig } from '@chakra-ui/react';

const Badge = defineStyleConfig({
  variants: {
    rounded: () => ({
      borderRadius: 'full',
      bgColor: 'white',
      py: 0.5,
      px: 3,
      textTransform: 'capitalize',
    })
  }
});

export default Badge;
