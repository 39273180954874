import React from 'react';
import { useTranslation } from 'react-i18next';
import { Flex, Heading, HStack, Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import FontAwesomeIcon from '@/shared/components/FontAwesomeIcon';
import UsaFlag from '@/assets/images/US.svg';
import SpainFlag from '@/assets/images/ES.svg';
import VietnamFlag from '@/assets/images/Vietnam.png';
import PhilippinesFlag from '@/assets/images/Philippines.png';
import SomaliFlag from '@/assets/images/SomaliFlag.png';
import SaudiArabiaFlag from '@/assets/images/SaudiArabia.png';
import JapanFlag from '@/assets/images/Japan.png';
import LaosFlag from '@/assets/images/Laos.png';
import ChinaFlag from '@/assets/images/China.png';
import KoreaFlag from '@/assets/images/Korean.png';
import CambodiaFlag from '@/assets/images/Cambodia.png';

const LanguageSelector: React.FC = () => {
  const { i18n } = useTranslation();

  const languages = [
    { code: 'en', label: 'English', flag: UsaFlag },
    { code: 'es', label: 'Spanish', flag: SpainFlag },
    { code: 'vi', label: 'Vietnamese', flag: VietnamFlag },
    { code: 'tl', label: 'Tagalog', flag: PhilippinesFlag },
    { code: 'so', label: 'Somali', flag: SomaliFlag },
    { code: 'ar', label: 'Arabic', flag: SaudiArabiaFlag },
    { code: 'ja', label: 'Japanese', flag: JapanFlag },
    { code: 'lo', label: 'Lao', flag: LaosFlag },
    { code: 'zh', label: 'Mandarin', flag: ChinaFlag },
    { code: 'ko', label: 'Korean', flag: KoreaFlag },
    { code: 'km', label: 'Khmer', flag: CambodiaFlag },
  ];

  const currentLanguageCode = i18n.language;

  const changeLanguage = (language: string) => {
    i18n.changeLanguage(language);
    localStorage.setItem('language', language);
  };

  const currentLanguage = languages.find(lang => lang.code === currentLanguageCode);

  return (
    <Menu>
      <HStack>
        <MenuButton cursor='pointer'>
          <Flex align='center' alignItems={'center'} gap={2} h='100%'>
            <Heading as='p' fontSize='sm' fontWeight={500} w='auto' verticalAlign={'center'} h='100%'>
              <img src={currentLanguage?.flag} alt={currentLanguage?.label} width={24} height={16} />
            </Heading>
            <FontAwesomeIcon boxSize={2} icon={faChevronDown} />
          </Flex>
        </MenuButton>
        <MenuList bg='themeNavCtxMenuBg'>
          {languages.map(language => (
            <MenuItem
              bg='themeNavCtxMenuBg'
              key={language.code}
              onClick={() => changeLanguage(language.code)}
              display='flex'
              alignItems='center'
              gap={2}
            >
              {language.label}
              <img src={language.flag} alt={language.label} width={24} height={16} />
            </MenuItem>
          ))}
        </MenuList>
      </HStack>
    </Menu>
  );
};

export default LanguageSelector;
