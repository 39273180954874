import { ReactNode } from 'react';
import {
  createMultiStyleConfigHelpers,
  createStylesContext,
  useMultiStyleConfig,
  FormControlProps,
  FormControl as ChakraFormControl,
} from '@chakra-ui/react';

const helpers = createMultiStyleConfigHelpers(['formControl', 'formLabel']);

export const FormControlStyles = helpers.defineMultiStyleConfig({
  baseStyle: {
    formControl: {
      mt: '4px !important',
    },
  },
});

type FormControlWithChildrenProps = {
  children: ReactNode;
} & FormControlProps;

const FormControl = (props: FormControlWithChildrenProps) => {
  const { size, variant, children, ...rest } = props;

  const styles = useMultiStyleConfig('FormControl', { size, variant });
  const [StylesProvider] = createStylesContext('FormControl');

  return (
    <ChakraFormControl __css={styles.formControl} {...rest}>
      <StylesProvider value={styles}>{children}</StylesProvider>
    </ChakraFormControl>
  );
};

export default FormControl;
