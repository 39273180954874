import { SWRConfiguration } from 'swr';
import { authenticatedAxiosInstance } from './instances';

export interface UseApiConfig extends SWRConfiguration {
  blockFetching?: boolean;
}

export interface FetchOptions {
  url: string;
  params?: Record<string, unknown>;
}

export interface FetcherKey extends FetchOptions {
  getToken: () => Promise<string>;
}

export interface FetcherResponse<TData> {
  data: TData;
  headers: Record<string, string>;
  status: number;
  statusText: string;
}

export async function fetcher<TData>({ url, params, getToken }: FetcherKey): Promise<FetcherResponse<TData>> {
  const token = await getToken();

  const { data, headers, status, statusText } = await authenticatedAxiosInstance<TData>({
    method: 'GET',
    url,
    params,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  return {
    data,
    status,
    statusText,
    headers: JSON.parse(JSON.stringify(headers)),
  };
}

export const defaultSwrConfig: SWRConfiguration = {
  revalidateOnFocus: false,
};
