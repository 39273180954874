import { Heading, Tab } from '@chakra-ui/react';
import { ReactNode } from 'react';

type TabHeaderProps = {
  children: ReactNode;
};

export const TabHeader = ({ children }: TabHeaderProps) => {
  return (
    <Tab flexGrow={1} flexShrink={1}>
      <Heading as='h1' size='sm' textAlign='center'>
        {children}
      </Heading>
    </Tab>
  );
};
