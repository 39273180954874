import { useLogo } from '@/shared/hooks/useLogo';
import { Flex, Image, Link } from '@chakra-ui/react';

export const Logo = () => {
  const logo = useLogo();

  return (
    <Flex grow={1} basis={0} align='center' gap={5}>
      <Link href='/' data-cy='sandiego-logo'>
        <Image mr='auto' src={logo} alt='logo-header' height={10} />
      </Link>
    </Flex>
  );
};
