import tevora from '@/assets/images/tevora.webp';
import fallbackImage from '@/assets/images/fallback-service-app.webp';

export const SERVICE_APP_IMAGES = {
  fallbackImage,
  tevora,
};

export const getServiceAppImageSrc = (name: string): string => {
  return name?.includes('https://') || name?.includes('http://') ? name : SERVICE_APP_IMAGES.fallbackImage;
};
