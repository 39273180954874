import { useAuth0 } from '@auth0/auth0-react';
import {
  Flex,
  Heading,
  HStack,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  SkeletonText,
  useColorMode,
} from '@chakra-ui/react';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import { useUserContext } from '@/modules/users/components/UserProvider';
import FontAwesomeIcon from '@/shared/components/FontAwesomeIcon';

export const ProfileButton = () => {
  const { user } = useUserContext();
  const { logout, isLoading } = useAuth0();
  const { colorMode, toggleColorMode } = useColorMode();

  const toggleModeMessage = `Toggle ${colorMode === 'light' ? 'Dark' : 'Light'}`;

  return (
    <Menu>
      <HStack>
        <MenuButton cursor='pointer' data-cy='logged-user-name-actions-button'>
          <Flex align='center' alignItems={'center'} gap={2} h='100%'>
            {isLoading && <SkeletonText w='40' noOfLines={1} skeletonHeight='18' />}
            {!isLoading && (
              <Heading as='p' fontSize='sm' fontWeight={500} w='auto' verticalAlign={'center'} h='100%'>
                {user?.name}
              </Heading>
            )}
            <FontAwesomeIcon boxSize={2} icon={faChevronDown} />
          </Flex>
        </MenuButton>
        <MenuList bg='themeNavCtxMenuBg'>
          <MenuItem onClick={toggleColorMode} data-cy='toggle-theme-button' bg='themeNavCtxMenuBg'>
            {toggleModeMessage}
          </MenuItem>
          <MenuItem onClick={() => logout()} data-cy='logout-button' bg='themeNavCtxMenuBg'>
            Logout
          </MenuItem>
        </MenuList>
      </HStack>
    </Menu>
  );
};
