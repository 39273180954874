import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  Slide,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt, faKey } from '@fortawesome/free-solid-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { z } from 'zod';
import { getUserMetadata, sendPasswordReset, updateUserProfile } from '@/api';
import ThemeColors from '@/shared/theme/colors/ThemeColors';
import { useDeleteMfa } from './useDeleteMfa';
import { TFunction } from 'i18next';

const useTranslatedSchema = (t: TFunction) => {
  return z.object({
    firstName: z.string().regex(/^[A-Za-z\s]+$/, { message: t('Invalid first name') }),
    lastName: z.string().regex(/^[A-Za-z\s]+$/, { message: t('Invalid last name') }),
    email: z.string().email(),
    phoneNumber: z
      .string()
      .refine((value): value is string => value === '' || (!isNaN(Number(value)) && value.length >= 10), {
        message: t('Phone Number must be a number'),
      }),
    streetAddress: z.string(),
    zipcode: z
      .string()
      .refine((value): value is string => value === '' || (!isNaN(Number(value)) && value.length >= 5), {
        message: t('Zipcode must be a number'),
      }),
  });
};

type FormValues = z.infer<ReturnType<typeof useTranslatedSchema>>;

const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  streetAddress: '',
  zipcode: '',
};

const defaultLabels = {
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  phoneNumber: 'Phone Number',
  streetAddress: 'Street Address',
  zipcode: 'Zip Code',
};

type TabServicesProps = {
  show: boolean;
};

type IKey = keyof typeof defaultLabels;

export const TabProfile = ({ show }: TabServicesProps) => {
  const { user, getAccessTokenSilently } = useAuth0();
  const toast = useToast();
  const { t, i18n } = useTranslation();
  const { onDelete } = useDeleteMfa(user!.sub!);

  // Use useFormContext to access form methods
  // Use useForm hook
  const { handleSubmit, control, setValue, formState, getValues, trigger } = useForm<FormValues>({
    resolver: zodResolver(useTranslatedSchema(t)),
    defaultValues,
    mode: 'onChange',
  });

  useEffect(() => {
    const resetFormLanguage = () => {
      // Update error messages individually
      const phoneNumber = getValues('phoneNumber');
      setValue('phoneNumber', ''); // Clear the existing error message
      setValue('phoneNumber', phoneNumber, { shouldValidate: true }); // Trigger validation to show the updated error message

      const zipcode = getValues('zipcode');
      setValue('zipcode', '');
      setValue('zipcode', zipcode, { shouldValidate: true });

      // Repeat this for other form fields

      // Note: You may want to handle clearing and triggering validation for each field separately
    };

    if (show) {
      const fetchUserMetadata = async () => {
        try {
          setValue('email', user!.email || '');
          const data = await getUserMetadata(user!.sub!, getAccessTokenSilently);
          if (!data) return;
          setValue('firstName', data.first_name || '');
          setValue('lastName', data.last_name || '');
          setValue('streetAddress', data.street_address || '');
          // Use getValues to get the current values
          if (data.phone_number) {
            setValue('phoneNumber', data.phone_number, { shouldValidate: false });
          }

          if (data.zip_code) {
            setValue('zipcode', data.zip_code, { shouldValidate: false });
          }

          // Trigger validation after setting values
          trigger();
        } catch {
          toast({ status: 'error', title: t('Error fetching user data') });
        }
      };

      fetchUserMetadata();
    }

    i18n.on('languageChanged', resetFormLanguage);

    return () => {
      i18n.off('languageChanged', resetFormLanguage);
    };
  }, [show, user, getAccessTokenSilently, setValue, getValues, toast, t]);

  const onSubmit = async (data: FormValues) => {
    try {
      await updateUserProfile(data, user!.sub!, getAccessTokenSilently);
      toast({ status: 'success', title: t('Profile updated successfully.') });
    } catch {
      toast({ status: 'error', title: t('Error updating profile.') });
    }
  };

  const onResetPassword = async () => {
    try {
      await sendPasswordReset(user!.email!);
      toast({ status: 'success', title: t('Reset Password email sent.') });
    } catch {
      toast({ status: 'error', title: t('Error sending Password Reset Email.') });
    }
  };

  const onResetMfa = async () => {
    await onDelete();
  };

  if (!show) return null;

  return (
    <Slide direction={'right'} in style={{ position: 'absolute', marginTop: '3rem' }}>
      <Flex justifyContent='center' alignItems='center' direction='column' py='4'>
        <VStack
          as='form'
          onSubmit={handleSubmit(onSubmit)}
          spacing={1}
          align='stretch'
          py='8'
          px='16'
          width={['90%', '90%', '90%', '50%']}
          maxWidth={['initial', 'initial', 'initial', '600px']}
          sx={{
            borderRadius: '4px',
            border: '1px solid',
            borderColor: 'themeBoxBorder',
          }}
        >
          {Object.entries(defaultValues).map(([key, defaultValue]) => (
            <FormControl key={key} isInvalid={!!formState.errors[key as IKey]}>
              <FormLabel color={ThemeColors.themeTextColor}>
                {t(defaultLabels[key as keyof typeof defaultLabels])}
              </FormLabel>
              <Controller
                control={control}
                name={key as IKey}
                defaultValue={defaultValue}
                render={({ field }) => (
                  <Input {...field} isReadOnly={key === 'email'} isInvalid={!!formState.errors[key as IKey]} />
                )}
              />
              <FormErrorMessage>{formState.errors[key as IKey]?.message}</FormErrorMessage>
            </FormControl>
          ))}
          <Box h='100%'>
            <Flex direction='row' gap={10} align='center' justifyContent='center'>
              <Button
                aria-label={t('Reset Password')}
                leftIcon={<FontAwesomeIcon icon={faKey} />}
                colorScheme='ButtonText'
                fontSize='16'
                onClick={onResetPassword}
                variant='ghost'
              >
                {t('Reset Password')}
              </Button>
              <Button
                aria-label={t('Reset MFA')}
                leftIcon={<FontAwesomeIcon icon={faSyncAlt} />}
                colorScheme='ButtonText'
                fontSize='16'
                onClick={onResetMfa}
                variant='ghost'
              >
                {t('Reset MFA')}
              </Button>
            </Flex>
          </Box>
          <Center>
            <Button mt={4} isLoading={formState.isSubmitting} type='submit' disabled={!formState.isDirty} fontSize='16'>
              {t('Save Profile')}
            </Button>
          </Center>
        </VStack>
      </Flex>
    </Slide>
  );
};
