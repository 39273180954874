import { defineStyleConfig } from '@chakra-ui/react';
import ThemeColors from '../colors/ThemeColors';

const Button = defineStyleConfig({
  variants: {
    default: {
      background: ThemeColors.themeDefaultButton,
      textColor: ThemeColors.accentWhite,
      borderRadius: '4px',
      lineHeight: '1.4',
      padding: '.75em 2em'
    },
    cancel: {
      background: 'transparent',
    },
  },
  defaultProps: {
    size: 'lg',
    variant: 'default',
  },
});

export default Button;
