import { useAuth0 } from '@auth0/auth0-react';
import { Button, Center, Flex } from '@chakra-ui/react';
import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';

const Auth0Error: FC = () => {
  const { t } = useTranslation();
  const { logout } = useAuth0();

  return (
    <>
      <Helmet>
        <title>Unauthorized</title>
      </Helmet>
      <Center h='100vh'>
        <Flex direction='column' justifyContent='center' alignItems='center' mb='4'>
          <Button mt={5} size={'sm'} onClick={() => logout()}>
            {t('Return to login')}
          </Button>
        </Flex>
      </Center>
    </>
  );
};

export default Auth0Error;
